import { Injectable } from '@angular/core';
import { ResoursesService } from 'src/app/services/resourses.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResp } from '../models/apiResp';
import { environment } from '../../environments/environment';
import { appoinmentsRoutes } from '../global/apiRoutes.routes';
import { map, catchError } from 'rxjs/operators';
import { Appointment } from '../models/appointment.interface';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  constructor(
    private resoucers: ResoursesService,
    private http: HttpClient
  ) { }

  public save(data: any): Observable<any> {
    return this.http.post<ApiResp>(environment.apiUrl + appoinmentsRoutes.save, data, { headers: this.resoucers.getHeaders() })
      .pipe(
        map(resp => this.resoucers.handleResponse(resp, '/app/consultas/registrar', null, 'Consulta registrada')),
        catchError(error => this.resoucers.handleError(error)));
  }

  public getAll(): Observable<Appointment[]> {
    return this.http.get<ApiResp>(environment.apiUrl + appoinmentsRoutes.getAll, { headers: this.resoucers.getHeaders() })
      .pipe(
        map(resp => resp.data),
        catchError(error => this.resoucers.handleError(error)));
  }

  public updateStatus(data: any): Observable<any> {
    return this.http.post<ApiResp>(environment.apiUrl + appoinmentsRoutes.updateStatus, data, { headers: this.resoucers.getHeaders() })
      .pipe(
        map(resp => this.resoucers.handleResponse(resp, null, null, 'Actualizada')),
        catchError(error => this.resoucers.handleError(error)));
  }

  public getById(id: string): Observable<Appointment> {
    return this.http.get<ApiResp>(environment.apiUrl + appoinmentsRoutes.getById(id), { headers: this.resoucers.getHeaders() }).
      pipe(
        map(resp => resp.data),
        catchError(error => this.resoucers.handleError(error)));
  }

  public update(appointment: any): Observable<any> {
    return this.http.put<ApiResp>(environment.apiUrl + appoinmentsRoutes.update, appointment, { headers: this.resoucers.getHeaders() })
      .pipe(
        map(resp => this.resoucers.handleResponse(resp, `app/consultas/listado`, null, 'Actualizada')),
        catchError(error => this.resoucers.handleError(error)));
  }

  public getAppointmentsTodayByIdDoctor(idDcotor: string): Observable<Appointment[]> {
    return this.http.get<ApiResp>(environment.apiUrl + appoinmentsRoutes.getToday(idDcotor), { headers: this.resoucers.getHeaders() })
      .pipe(
        map(resp => resp.data),
        catchError(error => this.resoucers.handleError(error)));
  }

  public getByIdDoctor(id: string): Observable<Appointment[]> {
    return this.http.get<ApiResp>(environment.apiUrl + appoinmentsRoutes.getByDoctor(id), { headers: this.resoucers.getHeaders() })
      .pipe(
        map(resp => resp.data),
        catchError(error => this.resoucers.handleError(error)));
  }

  public deleteById(id: string): Observable<any> {
    return this.http.delete<ApiResp>(environment.apiUrl + appoinmentsRoutes.delete(id), { headers: this.resoucers.getHeaders() })
      .pipe(
        map(resp => this.resoucers.handleResponse(resp)),
        catchError(error => this.resoucers.handleError(error)));
  }

  public registerPayment(paymentData: any): Observable<any> {
    return this.http.post<ApiResp>(environment.apiUrl + appoinmentsRoutes.registerPayment, paymentData, { headers: this.resoucers.getHeaders() })
      .pipe(
        map(resp => this.resoucers.handleResponse(resp)),
        catchError(error => this.resoucers.handleError(error)));
  }

  public getTodayAppointments(): Observable<Appointment[]> {
    return this.http.get<ApiResp>(environment.apiUrl + appoinmentsRoutes.getTodayAppointmets, { headers: this.resoucers.getHeaders() })
      .pipe(
        map(resp => resp.data),
        catchError(error => this.resoucers.handleError(error)))
  }

  public getAppointmentsByDate(date: string): Observable<Appointment[]> {
    return this.http.get<ApiResp>(environment.apiUrl + appoinmentsRoutes.getAppointmentsByDate(date), { headers: this.resoucers.getHeaders() })
      .pipe(
        map(resp => resp.data),
        catchError(error => this.resoucers.handleError(error)))
  }
}
