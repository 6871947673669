<h1 mat-dialog-title>Registro para pago de consulta </h1>
<div mat-dialog-content>
    <div class="col-12">
        <div class="row">
            <div class="col-12">
                <form [formGroup]="paymentForm" class="row">

                    <div class="col-12 col-md-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>Método de pago</mat-label>
                            <mat-select formControlName="paymentMethod">
                                <mat-option value="EFECTIVO"> EFECTIVO </mat-option>
                                <mat-option value="TRANSFERENCIABANCARIA"> TRANSFERENCIA BANCARIA </mat-option>
                                <mat-option value="TARJETA"> TARJETA CRÉDITO/DÉBITO </mat-option>
                                <mat-option value="CONSIGANACION"> CONSIGANACIÓN </mat-option>
                                <mat-option value="BCP-INTERBANK-BBVA-OTROS"> BCP INTERBANK BBVA y otros </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-12 col-md-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>Valor</mat-label>
                            <input formControlName="value" matInput type="number">
                        </mat-form-field>
                    </div>

                    <div class="col-12 col-md-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>Fecha de pago</mat-label>
                            <input formControlName="registerDate" matInput type="date">
                        </mat-form-field>
                    </div>

                    <!-- <div class="col-12 col-md-6" *ngIf="paymentForm.value.paymentMethod !== 'EFECTIVO'">
                        <mat-form-field class="example-full-width">
                            <mat-label>Cuenta bancaria</mat-label>
                            <input formControlName="bankAccount" matInput type="text" placeholder="Cuenta donde se hizo la consigación">
                        </mat-form-field>
                    </div> -->

                    <div class="col-12 col-md-6" *ngIf="paymentForm.value.paymentMethod !== 'EFECTIVO'">
                        <mat-form-field class="example-full-width">
                            <mat-label>Cuenta bancaria</mat-label>
                            <mat-select formControlName="bankAccount" matInput placeholder="Cuenta donde se hizo la consigación">
                                <mat-option *ngFor="let item of bankList" value="item.name">{{ item.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-12 col-md-6" *ngIf="paymentForm.value.paymentMethod !== 'EFECTIVO'">
                        <mat-form-field class="example-full-width">
                            <mat-label>Número de operación</mat-label>
                            <input formControlName="operationCode" matInput type="text" placeholder="Número codigo de operación">
                        </mat-form-field>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>
<div class="col-12">
    <div mat-dialog-actions class="row justify-content-between">

        <button class="btn  btn-raised bg-teal btn-sm waves-effect c-white " [mat-dialog-close]="paymentForm.value" mat-buttonCancelar [disabled]="paymentForm.invalid">
      <i class="zmdi zmdi-close mr-3"></i>
      CERRAR
    </button>
    </div>
</div>
