import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Appointment } from 'src/app/models/appointment.interface';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ConsultationAdmitionService } from 'src/app/services/consultation-admition.service';
import { ResoursesService } from 'src/app/services/resourses.service';

@Component({
  selector: 'app-register-admition-modal',
  templateUrl: './register-admition-modal.component.html',
  styleUrls: ['./register-admition-modal.component.scss']
})
export class RegisterAdmitionModalComponent implements OnInit {

  public medicalEvaluation: FormGroup;
  public imc: number;

  constructor(
    public dialogRef: MatDialogRef<RegisterAdmitionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public appointment: Appointment,
    private builder: FormBuilder,
    private resources: ResoursesService,
  ) {
    this.setMedicalEvaluationForm();
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setSomething(value: number): void {
    const total: number = Number((this.medicalEvaluation.value.peso / (this.medicalEvaluation.value.talla * 2)).toFixed(2));
    this.imc = total;
  }

  private setMedicalEvaluationForm(): void {
    this.medicalEvaluation = this.builder.group({
      talla: [null, Validators.required],
      peso: [null, Validators.required],
      perimetroabdominal: [null],
      imc: [null, Validators.required],
      satO2: [null, Validators.required],
      fr: [null, Validators.required],
      fc: [null, Validators.required],
      pa: [null, Validators.required],
      createdBy: [this.resources.getUser()._id],
      createdAt: [new Date()],
      patient: [this.appointment.patient._id],
      appointment: [this.appointment._id]
    });
  }

}
