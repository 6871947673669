import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { Patient } from 'src/app/models/patient';
import { UserService } from 'src/app/services/user.service';
import { ResoursesService } from 'src/app/services/resourses.service';
import { HttpClient } from '@angular/common/http';
import { PatientsService } from 'src/app/services/patients.service'

@Component({
  selector: 'app-rightsidebar',
  templateUrl: './rightsidebar.component.html',
  styleUrls: ['./rightsidebar.component.scss']
})
export class RightsidebarComponent implements OnInit {

  public user: User = new Object();
  public dahboardData: any = new Object();
  public patientsNotEvaluated: any[] = [];
  public notEvaluatedExist: boolean = false;

  constructor(
    public userSrv: UserService,
    public resources: ResoursesService,
    private http: HttpClient,
    private patientSrv: PatientsService
  ) {
    if (localStorage.getItem('userLogged')) {
      this.user = JSON.parse(localStorage.getItem('userLogged'));
    }
    patientSrv.getPatientsNotEvaluated(this.user._id).subscribe(res => {
      this.notEvaluatedExist = res.length > 0;
      // this.patientsNotEvaluated = res;
    })
  }

  ngOnInit() {}


}
