import { Component, OnInit, Input } from '@angular/core';
import { AnxietyScale, DepressionScale } from 'src/app/models/patientConsultation';

@Component({
  selector: 'app-anxiety-detail-accordion',
  templateUrl: './anxiety-detail-accordion.component.html',
  styleUrls: ['./anxiety-detail-accordion.component.scss']
})
export class AnxietyDetailAccordionComponent implements OnInit {

  @Input() i: number;
  @Input() anxietyScale: AnxietyScale;
  @Input() depressionScale: DepressionScale;

  constructor() {
  }

  ngOnInit(): void {
    this.anxietyScale.count = 0;
    this.depressionScale.count = 0;
  }

  public countAnxietyScale(): void {
    this.anxietyScale.count = 0;
    Object.entries(this.anxietyScale).forEach(entry => {
      const [key, value] = entry;
      if (key != 'count' && value) this.anxietyScale.count++;
    });
  }

  public countDepressionScale(): void {
    this.depressionScale.count = 0;
    Object.entries(this.depressionScale).forEach(entry => {
      const [key, value] = entry;
      if (key != 'count' && value) this.depressionScale.count++;
    });
  }
}
