    <div class="panel-group mat-elevation-z10" [id]="'accordion_' + '_' +  (i + 1)" role="tablist" aria-multiselectable="true">
        <div class="panel panel-col-grey">
            <div class="panel-heading" role="tab" id="headingOne_17">
                <h4 class="panel-title">
                    <a role="button"
                       data-toggle="collapse"
                       [attr.data-parent]="'#accordion_' + '_' +  (i + 1)"
                       [href]="'#' + 'collapseOne_' + '_' +  (i + 1)"
                       aria-expanded="false"
                       aria-controls="collapseOne_17"
                       class="collapsed">
                        <i class="material-icons">perm_contact_calendar</i>
                        <div class="row">
                        <span class="col-12 col-md-9 align-content-end"> Escala  de edmonton </span>
                        </div>
                    </a>
                </h4>
            </div>
            <div [id]="'collapseOne_' + '_' +  (i + 1)"
                 class="panel-collapse collapse in"
                 role="tabpanel"
                 aria-labelledby="headingOne_17"
                 aria-expanded="false">
                <div class="panel-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12">
                                    <div class="card-tite"> Dolor [Nada de dolor - El peor dolor que se pueda imaginar] </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="edmontonScale.pain" name="pain">
                                            <mat-option value="0"> 0</mat-option>
                                            <mat-option value="1"> 1</mat-option>
                                            <mat-option value="2"> 2</mat-option>
                                            <mat-option value="3"> 3</mat-option>
                                            <mat-option value="4"> 4</mat-option>
                                            <mat-option value="5"> 5</mat-option>
                                            <mat-option value="6"> 6</mat-option>
                                            <mat-option value="7"> 7</mat-option>
                                            <mat-option value="8"> 8</mat-option>
                                            <mat-option value="9"> 9</mat-option>
                                            <mat-option value="10"> 10</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="card-tite"> Agotado [Nada agotado - Lo más agotado que se pueda imaginar] </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="edmontonScale.exhausted" name="exhausted">
                                            <mat-option value="0"> 0</mat-option>
                                            <mat-option value="1"> 1</mat-option>
                                            <mat-option value="2"> 2</mat-option>
                                            <mat-option value="3"> 3</mat-option>
                                            <mat-option value="4"> 4</mat-option>
                                            <mat-option value="5"> 5</mat-option>
                                            <mat-option value="6"> 6</mat-option>
                                            <mat-option value="7"> 7</mat-option>
                                            <mat-option value="8"> 8</mat-option>
                                            <mat-option value="9"> 9</mat-option>
                                            <mat-option value="10"> 10</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="card-tite"> Somnoliento [Nada somnoliento - Lo más somnoliento que se pueda imaginar] </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="edmontonScale.somnolent" name="somnolent">
                                            <mat-option value="0"> 0</mat-option>
                                            <mat-option value="1"> 1</mat-option>
                                            <mat-option value="2"> 2</mat-option>
                                            <mat-option value="3"> 3</mat-option>
                                            <mat-option value="4"> 4</mat-option>
                                            <mat-option value="5"> 5</mat-option>
                                            <mat-option value="6"> 6</mat-option>
                                            <mat-option value="7"> 7</mat-option>
                                            <mat-option value="8"> 8</mat-option>
                                            <mat-option value="9"> 9</mat-option>
                                            <mat-option value="10"> 10</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="card-tite"> Nausea [Sin néuseas - Las peores nauseas que se pueda imaginar] </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="edmontonScale.nausea" name="nausea">
                                            <mat-option value="0"> 0</mat-option>
                                            <mat-option value="1"> 1</mat-option>
                                            <mat-option value="2"> 2</mat-option>
                                            <mat-option value="3"> 3</mat-option>
                                            <mat-option value="4"> 4</mat-option>
                                            <mat-option value="5"> 5</mat-option>
                                            <mat-option value="6"> 6</mat-option>
                                            <mat-option value="7"> 7</mat-option>
                                            <mat-option value="8"> 8</mat-option>
                                            <mat-option value="9"> 9</mat-option>
                                            <mat-option value="10"> 10</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="card-tite"> Apetito [Ninguna pérdida de apetito - El peor apetito que se pueda imaginar] </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="edmontonScale.apetito" name="apetito">
                                            <mat-option value="0"> 0</mat-option>
                                            <mat-option value="1"> 1</mat-option>
                                            <mat-option value="2"> 2</mat-option>
                                            <mat-option value="3"> 3</mat-option>
                                            <mat-option value="4"> 4</mat-option>
                                            <mat-option value="5"> 5</mat-option>
                                            <mat-option value="6"> 6</mat-option>
                                            <mat-option value="7"> 7</mat-option>
                                            <mat-option value="8"> 8</mat-option>
                                            <mat-option value="9"> 9</mat-option>
                                            <mat-option value="10"> 10</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="card-tite"> Dificultad para respirar [Ninguna dificultad para respirar - La mayor dificultad para respirar que se pueda imaginar] </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="edmontonScale.breathe" name="breathe">
                                            <mat-option value="0"> 0</mat-option>
                                            <mat-option value="1"> 1</mat-option>
                                            <mat-option value="2"> 2</mat-option>
                                            <mat-option value="3"> 3</mat-option>
                                            <mat-option value="4"> 4</mat-option>
                                            <mat-option value="5"> 5</mat-option>
                                            <mat-option value="6"> 6</mat-option>
                                            <mat-option value="7"> 7</mat-option>
                                            <mat-option value="8"> 8</mat-option>
                                            <mat-option value="9"> 9</mat-option>
                                            <mat-option value="10"> 10</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="card-tite"> Desanimado [Nada desanimado - Lo más desanimado que se pueda imaginar] </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="edmontonScale.discouraged" name="discouraged">
                                            <mat-option value="0"> 0</mat-option>
                                            <mat-option value="1"> 1</mat-option>
                                            <mat-option value="2"> 2</mat-option>
                                            <mat-option value="3"> 3</mat-option>
                                            <mat-option value="4"> 4</mat-option>
                                            <mat-option value="5"> 5</mat-option>
                                            <mat-option value="6"> 6</mat-option>
                                            <mat-option value="7"> 7</mat-option>
                                            <mat-option value="8"> 8</mat-option>
                                            <mat-option value="9"> 9</mat-option>
                                            <mat-option value="10"> 10</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="card-tite"> Nervioso [Nada nervioso (intranquilidad, ansiedad) - Lo más nervioso que se pueda imaginar] </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="edmontonScale.nervous" name="nervous">
                                            <mat-option value="0"> 0</mat-option>
                                            <mat-option value="1"> 1</mat-option>
                                            <mat-option value="2"> 2</mat-option>
                                            <mat-option value="3"> 3</mat-option>
                                            <mat-option value="4"> 4</mat-option>
                                            <mat-option value="5"> 5</mat-option>
                                            <mat-option value="6"> 6</mat-option>
                                            <mat-option value="7"> 7</mat-option>
                                            <mat-option value="8"> 8</mat-option>
                                            <mat-option value="9"> 9</mat-option>
                                            <mat-option value="10"> 10</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="card-tite"> Duermo [Duermo perfectamente - La mayor dificultad para dormir que se pueda imaginar] </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="edmontonScale.sleep" name="sleep">
                                            <mat-option value="0"> 0</mat-option>
                                            <mat-option value="1"> 1</mat-option>
                                            <mat-option value="2"> 2</mat-option>
                                            <mat-option value="3"> 3</mat-option>
                                            <mat-option value="4"> 4</mat-option>
                                            <mat-option value="5"> 5</mat-option>
                                            <mat-option value="6"> 6</mat-option>
                                            <mat-option value="7"> 7</mat-option>
                                            <mat-option value="8"> 8</mat-option>
                                            <mat-option value="9"> 9</mat-option>
                                            <mat-option value="10"> 10</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="card-tite"> Sentirse [Sentirse perfectamente (sensación de bienestar) - Sentirse lo peor que se pueda imaginar] </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="edmontonScale.feel" name="feel">
                                            <mat-option value="0"> 0</mat-option>
                                            <mat-option value="1"> 1</mat-option>
                                            <mat-option value="2"> 2</mat-option>
                                            <mat-option value="3"> 3</mat-option>
                                            <mat-option value="4"> 4</mat-option>
                                            <mat-option value="5"> 5</mat-option>
                                            <mat-option value="6"> 6</mat-option>
                                            <mat-option value="7"> 7</mat-option>
                                            <mat-option value="8"> 8</mat-option>
                                            <mat-option value="9"> 9</mat-option>
                                            <mat-option value="10"> 10</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="card-tite"> Otro problema [Nada Otro problema (par ej; sequedad de boca) - Lo peor posible] </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="edmontonScale.anotherProblem" name="anotherProblem">
                                            <mat-option value="0"> 0</mat-option>
                                            <mat-option value="1"> 1</mat-option>
                                            <mat-option value="2"> 2</mat-option>
                                            <mat-option value="3"> 3</mat-option>
                                            <mat-option value="4"> 4</mat-option>
                                            <mat-option value="5"> 5</mat-option>
                                            <mat-option value="6"> 6</mat-option>
                                            <mat-option value="7"> 7</mat-option>
                                            <mat-option value="8"> 8</mat-option>
                                            <mat-option value="9"> 9</mat-option>
                                            <mat-option value="10"> 10</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
