import { Injectable } from '@angular/core';
import { CanActivate,  Router } from '@angular/router';
import { User } from 'src/app/models/user';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(
    private router: Router
  ) {}

  canActivate() {
    if (localStorage.getItem('userToken') && localStorage.getItem('userLogged')) {
      if (localStorage.getItem('userToken').length > 0) {
        return true;
      }
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

}
