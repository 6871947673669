<!-- Page Loader -->
<div class="page-loader-wrapper">
    <div class="loader">
        <div class="preloader">
            <div class="spinner-layer pl-cyan">
                <div class="circle-clipper left">
                    <div class="circle"></div>
                </div>
                <div class="circle-clipper right">
                    <div class="circle"></div>
                </div>
            </div>
        </div>
        <p>Cargando...</p>
    </div>
</div>
<!-- #END# Page Loader --> 
<!-- Overlay For Sidebars -->
<div class="overlay"></div>
<!-- #END# Overlay For Sidebars -->
<!-- #Float icon -->

<!-- #Float icon -->
<!-- Morphing Search  -->
<div id="morphsearch" class="morphsearch">
    <!-- <form class="morphsearch-form">
        <div class="form-group m-0">
            <input value="" type="search" placeholder="Buscar" class="form-control morphsearch-input" />
            <button class="morphsearch-submit" type="submit">Search</button>
        </div>
    </form> -->

    <!-- /morphsearch-content --> 
    <span class="morphsearch-close"></span> </div>
<!-- Top Bar -->
<nav class="navbar clearHeader">
    <div class="col-12">
        <div class="navbar-header"> <a href="javascript:void(0);" class="bars"></a> <a class="navbar-brand" href="index.html"> CannaHope </a> </div>
        <ul class="nav navbar-nav navbar-right">
            
            <li><a href="javascript:void(0);" (click)="openNotReevaluagedModal()">
                <i *ngIf="!notEvaluatedExist" class="zmdi zmdi-alarm"></i>
                <i *ngIf="notEvaluatedExist" class="zmdi zmdi-alarm alarm-notify"></i>
            </a></li>

            <!-- Tasks -->
            <!-- #END# Tasks -->
            <li><a href="javascript:void(0);" class="js-right-sidebar" data-close="true"><i class="zmdi zmdi-settings"></i>
            </a></li>
        </ul>
    </div>
</nav>
<section> 
    <app-left-sidebar [user]="user" ></app-left-sidebar>
    <app-rightsidebar></app-rightsidebar>
    <app-noreevaluated-sidebar></app-noreevaluated-sidebar>
</section>
<section class="content home" style="min-height: 100vh;">
    <div class="container-fluid">
        <router-outlet></router-outlet>
	</div>
</section>

<div class="color-bg"></div>
