import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidateRolGuard {


  constructor() { }

  public administrador(): boolean {
    return JSON.parse(localStorage.getItem('userLogged')).rol.name === 'Administrador' ? true : false;
  }

  public medico(): boolean {
    return JSON.parse(localStorage.getItem('userLogged')).rol.name === 'Médico' ? true : false;
  }

  public enfermera(): boolean {
    return JSON.parse(localStorage.getItem('userLogged')).rol.name === 'Enfermera' ? true : false;
  }
  public paciente(): boolean {
    return JSON.parse(localStorage.getItem('userLogged')).rol.name === 'Paciente' ? true : false;
  }
  public recepcionista(): boolean {
    return JSON.parse(localStorage.getItem('userLogged')).rol.name === 'Recepcionista' ? true : false;
  }
  public usuario(): boolean {
    return JSON.parse(localStorage.getItem('userLogged')).rol.name === 'Usuario' ? true : false;
  }
}
