import { Component, OnInit, ViewChild } from '@angular/core';
import { AppointmentService } from '../../services/appointment.service';
import { Appointment } from '../../models/appointment.interface';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ResoursesService } from 'src/app/services/resourses.service';
import { MatDialog } from '@angular/material/dialog';
import { RegisterPaymentComponent } from '../appointment/register-payment/register-payment.component';
import {
  RegisterAdmitionModalComponent
} from '../patient/patient-management/patient-appointments/register-admition-modal/register-admition-modal.component';
import { ConsultationAdmitionService } from 'src/app/services/consultation-admition.service';
import { RegisteredPaymentComponent } from '../appointment/registered-payment/registered-payment.component';
import { AppointmentStatusService } from '../../services/appointment-status.service';
import { Observable } from 'rxjs';
import { AppointmentStatus } from '../../models/appointment-status.interface';
import { DoctorService } from 'src/app/services/doctor.service';
import * as moment from 'moment';

@Component({
  selector: 'app-my-patients',
  templateUrl: './my-patients.component.html',
  styleUrls: ['./my-patients.component.scss']
})
export class MyPatientsComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public displayedColumns: string[] = ['name', 'surname', 'dni', 'sex', 'doctor'];
  public dataSource: MatTableDataSource<Appointment> = new MatTableDataSource<Appointment>([]);

  public appointmentList: Appointment[] = [];
  public searchFilter: string;
  public picker: any;
  public appointmentStatusList: Observable<AppointmentStatus[]>;
  public doctorList: Observable<any[]>;
  public filterParams: FilterParams = {
    doctor: '',
    searchParam: ''
  };

  public loading: boolean = true;
  constructor(
    private appointmentSrv: AppointmentService,
    private router: Router,
    private resources: ResoursesService,
    private dialog: MatDialog,
    private consultationAdmitionSrv: ConsultationAdmitionService,
    private appointmentStatusSrv: AppointmentStatusService,
    private doctorSrv: DoctorService
  ) { }

  ngOnInit(): void {
    this.setAppointmentList();
    this.appointmentStatusList = this.appointmentStatusSrv.findAll();
    this.doctorList = this.doctorSrv.getAll();
  }

  public filter() {
    const param: string = this.filterParams.searchParam.toLowerCase();
    const founded = this.appointmentList.filter(option =>
        (this.filterParams.doctor == "" || option.doctor._id === this.filterParams.doctor || this.filterParams.doctor == "") && 
        (param == "" || (option.patient.user.names.trim() + ' ' + option.patient.user.surenames.trim()).includes(param)));
    this.dataSource = new MatTableDataSource<Appointment>(founded);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public editAppointment(appointment: Appointment): void {
    this.router.navigate([`app/consultas/editarConsulta/${appointment._id}`]);
  }

  public updateStatus(appointment: Appointment, status: string): void {
    const data = {
      status,
      idAppointment: appointment._id
    };
    this.appointmentSrv.updateStatus(data).subscribe(resp => {
      this.setAppointmentList();
    });
  }

  public async cancelAppointment(appointment: Appointment): Promise<any> {
    const validated: boolean = await this.resources.showAlert('¿Está seguro que desea cancelar esta consulta?', 'No podrá deshacer esta acción');
    if (validated) {
      this.appointmentSrv.deleteById(appointment._id).subscribe(resp => {
        this.setAppointmentList();
      });
    }
  }

  public openRegisterPaymentDialog(appointment: Appointment): void {
    const dialogRef = this.dialog.open(RegisterPaymentComponent, {
      width: '750px',
      data: appointment
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.registerPayment(result);
      }
    });
  }

  public showPaymentData(paymentData: any): void {
    const dialogRef = this.dialog.open(RegisteredPaymentComponent, {
      width: '650px',
      data: paymentData
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  private registerPayment(paymentData: any): void {
    this.appointmentSrv.registerPayment(paymentData).subscribe(resp => {
      this.setAppointmentList();
    });
  }

  public registerAdmision(appointment: Appointment): void {
    const dialogRef = this.dialog.open(RegisterAdmitionModalComponent, {
      width: '850px',
      data: appointment,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.consultationAdmitionSrv.save(result).subscribe(resp => {
          this.setAppointmentList();
        });
      }
    });
  }

  public setFilerParam(param: string): void {

  }

  public filterByDoctor($event: string): void {
    this.filterParams.doctor = $event;
    this.filter();
    return;
    const appointmentsFounded: Appointment[] = this.appointmentList.filter(a => a.doctor._id === $event);
    this.dataSource = new MatTableDataSource<Appointment>(appointmentsFounded);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  private setAppointmentList(): void {
    this.appointmentSrv.getAll().subscribe(resp => {
      // this.dataSource = new MatTableDataSource<Appointment>(this.sortList(resp));
      this.dataSource = new MatTableDataSource<Appointment>(resp);

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
      this.appointmentList = resp;

    });
  }

  public clearFilter(): void {
    this.filterParams = {
      doctor: '',
      searchParam: ''
    };
    this.filter();
  }
}


interface FilterParams {
  doctor: string;
  searchParam: string;
}
