<app-page-header title="Reevaluaciones registradas" icon="zmdi zmdi-format-list-bulleted"></app-page-header>


<div class="alert alert-info" *ngIf="reevaluationList.length === 0">
    <strong>
        <i class="zmdi zmdi-alert-circle-o"></i>
        Sin reevaluaciones.
    </strong> Hasta la fecha, no hay reevaluaciones registradas a esta consulta
</div>

<div *ngIf="reevaluationList.length > 0">
    <div *ngFor="let item of reevaluationList; let i = index" class="panel-group mat-elevation-z10">
        <div class="panel panel-col-grey">
            <div class="panel-heading" role="tab" id="headingOne_17">
                <h4 class="panel-title">
                    <a role="button" data-toggle="collapse" [attr.data-parent]="'#accordion_' + '_' +  (i + 1)" [href]="'#' + 'collapseOne_' + '_' +  (i + 1)" aria-expanded="false" aria-controls="collapseOne_17" class="collapsed">
                        <i class="material-icons">perm_contact_calendar</i>
                        <div class="row"> <span class="col-12 col-md-9 align-content-end"> Reevaluación
                            {{ item.createDate | date: 'dd-MM-yyyy' }}
                        </span>
                        </div>

                    </a>
                </h4>
            </div>
            <div *ngIf="i < reevaluationList.length-1 || !spread" [id]="'collapseOne_' + '_' +  (i + 1)" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_17" aria-expanded="false">
                <div class="panel-body">
                    <h3>
                        Escala de dolor </h3>
                    <p> {{ item.painScale || 0 }} </p>

                    <hr>
                    <h3>
                        Escala de ansiedad </h3>
                    <p> {{ item.solicitudeScale || 0 }} </p>

                    <hr>
                    <h3>
                        Recomendaciones
                    </h3>
                    <p> {{ item.recomendations}} </p>
                    <hr>

                    <h3>
                        Conclusión luego de reevaluación.
                    </h3>
                    <p>
                        {{ item.description }}
                    </p>
                    <hr>

                    <div class="col-12 col-md-12 mt-4" *ngFor="let item of item.treatment ; let i = index">
                        <div class="row justify-content-between">
                            <span class="h5"> Tratamiento {{ i + 1 }} </span>
                        </div>
                        <div class="row mt-4 custom-border">
                            <div class="col-12 col-md-4">
                                <span> V. de administración. </span>
                                <input style="width: 100%;" [value]="item.viaAdministracion" type="text" disabled>
                            </div>
                            <div class="col-12 col-md-4">
                                <span> Fitocannabinoides. </span>
                                <input style="width: 100%" [value]="item.fitocannabinoides" type="text" disabled>
                            </div>
                            <div class="col-12 col-md-4">
                                <span> Ratio </span>
                                <input style="width: 100%" [value]="item.ratio" type="text" disabled>
                            </div>
                            <div class="col-12 col-md-4">
                                <span> Concentración </span>
                                <input style="width: 100%" [value]="item.concentracion" type="text" disabled>
                            </div>

                            <div class="col-12 col-md-4">
                                <span> Frecuencia </span>
                                <input style="width: 100%" [value]="item.concentracion" type="text" disabled>
                            </div>

                            <div class="col-12 col-md-4" *ngIf="item.viaAdministracion === 'Sublingual'">
                                <span> Gotas por día </span>
                                <input style="width: 100%" [value]="item.concentracion" type="text" disabled>
                            </div>

                            <div class="col-12">
                                <span> Observaciones </span>
                                <input style="width: 100%" [value]="item.observations" type="text" disabled>
                            </div>
                        </div>
                    </div>

                    <button class="btn btn-raised btn-default waves-effect" mat-button (click)="download(item)">
                    <i class="zmdi zmdi-print"></i>
                    Imprimir receta medica
                </button>

                    <button class="btn btn-raised btn-default waves-effect" mat-button (click)="sendReevaluationEmailToRecipe()">
                    <i class="zmdi zmdi-mail-send"></i>
                    Enviar por correo
                </button>

                </div>
            </div>
            <div *ngIf="i == reevaluationList.length-1 && spread" [id]="'collapseOne_' + '_' +  (i + 1)" class="panel-collapse collapse in show" role="tabpanel" aria-labelledby="headingOne_17" aria-expanded="true">
                <div class="panel-body">
                    <h3>
                        Escala de dolor </h3>
                    <p> {{ item.painScale || item.painScale}} </p>

                    <hr>
                    <h3>
                        Recomendaciones
                    </h3>
                    <p> {{ item.recomendations}} </p>
                    <hr>

                    <h3>
                        Conclusión luego de reevaluación.
                    </h3>
                    <p>
                        {{ item.description }}
                    </p>
                    <hr>

                    <div class="col-12 col-md-12 mt-4" *ngFor="let item of item.treatment ; let i = index">
                        <div class="row justify-content-between">
                            <span class="h5"> Tratamiento {{ i + 1 }} </span>
                        </div>
                        <div class="row mt-4 custom-border">
                            <div class="col-12 col-md-4">
                                <span> V. de administración. </span>
                                <input style="width: 100%;" [value]="item.viaAdministracion" type="text" disabled>
                            </div>
                            <div class="col-12 col-md-4">
                                <span> Fitocannabinoides. </span>
                                <input style="width: 100%" [value]="item.fitocannabinoides" type="text" disabled>
                            </div>
                            <div class="col-12 col-md-4">
                                <span> Ratio </span>
                                <input style="width: 100%" [value]="item.ratio" type="text" disabled>
                            </div>
                            <div class="col-12 col-md-4">
                                <span> Concentración </span>
                                <input style="width: 100%" [value]="item.concentracion" type="text" disabled>
                            </div>

                            <div class="col-12 col-md-4">
                                <span> Frecuencia </span>
                                <input style="width: 100%" [value]="item.concentracion" type="text" disabled>
                            </div>

                            <div class="col-12 col-md-4" *ngIf="item.viaAdministracion === 'Sublingual'">
                                <span> Gotas por día </span>
                                <input style="width: 100%" [value]="item.concentracion" type="text" disabled>
                            </div>

                            <div class="col-12">
                                <span> Observaciones </span>
                                <input style="width: 100%" [value]="item.observations" type="text" disabled>
                            </div>
                        </div>
                    </div>

                    <button class="btn btn-raised btn-default waves-effect" mat-button (click)="download(item)">
                    <i class="zmdi zmdi-print"></i>
                    Imprimir receta médica
                </button>

                    <button class="btn btn-raised btn-default waves-effect" mat-button (click)="sendReevaluationEmailToRecipe()">
                    <i class="zmdi zmdi-mail-send"></i>
                    Enviar por correo
                </button>

                </div>
            </div>
        </div>
    </div>

    <div class="col-12">
        <div mat-dialog-actions class="row justify-content-between">
            <button class="btn btn-raised btn-default waves-effect" mat-button (click)="onNoClick()">
            <i class="zmdi zmdi-close"></i>
            Cerrar
        </button>
        </div>
    </div>
