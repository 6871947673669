<app-page-header title="Lista de consultas registradas" icon="zmdi zmdi-format-list-bulleted" subtitle="Lista de consultas activas registradas">
</app-page-header>

<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>


<div class="row clearfix">
    <div class="col-12">
        <div class="card">
            <div class="body">
                <div class="row">

                    <div class="col-12 col-md-6">
                        <mat-form-field class="w100p">
                            <mat-label>Búsqueda de consulta</mat-label>
                            <input [(ngModel)]="filterParams.searchParam" (ngModelChange)="filter()" matInput placeholder="Escriba nombre de paciente o numero de documento">
                        </mat-form-field>
                    </div>

                    <div class="col-12 col-md-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Doctor</mat-label>
                            <mat-select [(ngModel)]="filterParams.doctor" (ngModelChange)="filterByDoctor($event)">
                                <mat-option *ngFor="let doctor of doctorList | async" [value]="doctor._id">
                                    {{doctor.names}} {{ doctor.surenames }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-12 col-md-2">
                        <button mat-button (click)="clearFilter()" class="btn btn-raised btn-sm bg-orange waves-effect pointer"> LIMPIAR</button>
                    </div>

                    <div class="col-12">
                        <mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons></mat-paginator>

                        <!-- ['patient', 'doctor', 'date', 'hour', 'status', 'paymentStatus', 'actions', 'specialty']; -->
                        <table style="width: 100%;" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

                            <!-- Patient Column -->
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class=""> Nombres </th>
                                <td mat-cell *matCellDef="let element"> {{element.patient.user.names}} </td>
                            </ng-container>

                            <ng-container matColumnDef="surname">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class=""> Apellidos </th>
                                <td mat-cell *matCellDef="let element"> {{element.patient.user.surenames}} </td>
                            </ng-container>

                            <ng-container matColumnDef="dni">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> DNI </th>
                                <td mat-cell *matCellDef="let element"> {{element.patient.user.document}} </td>
                            </ng-container>

                            <ng-container matColumnDef="sex">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Sexo </th>
                                <td mat-cell *matCellDef="let element"> {{element.patient.user.sex}} </td>
                            </ng-container>

                            <!-- Dcotor Column -->
                            <ng-container matColumnDef="doctor">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Doctor </th>
                                <td mat-cell *matCellDef="let element"> {{element.doctor.names}} {{element.doctor.surenames}} </td>
                            </ng-container>

                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Acciones </th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="btn-group" role="group">
                                        <button type="button" [disabled]="element.status === 'ASISTIDA' || element.status === 'CANCELADA' || element.status === 'VENCIDA' " class="btn btn-default waves-effect dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="zmdi zmdi-format-list-bulleted"></i> <span class="caret"></span> </button>
                                        <ul class="dropdown-menu">

                                            <li>
                                                <a (click)="registerAdmision(element)" href="javascript:void(0);">
                                                    <i class="zmdi zmdi-check"></i> Registro de admisión
                                                </a>
                                            </li>

                                            <li>
                                                <a (click)="editAppointment(element)" href="javascript:void(0);">
                                                    <i class="zmdi zmdi-edit"></i> Editar
                                                </a>
                                            </li>

                                            <li>
                                                <a (click)="cancelAppointment(element)" href="javascript:void(0);">
                                                    <i class="zmdi zmdi-close"></i> Cancelar
                                                </a>
                                            </li>

                                            <li *ngIf="element.paymentStatus === 'PAGADO'">
                                                <a (click)="showPaymentData(element.paymentData)" href="javascript:void(0);">
                                                    <i class="zmdi zmdi-close"></i> Ver info. de pago
                                                </a>
                                            </li>

                                            <li *ngIf="element.paymentStatus !== 'PAGADO'">
                                                <a (click)="openRegisterPaymentDialog(element)" href="javascript:void(0);">
                                                    <i class="zmdi zmdi-card"></i> Registrar pago
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
