import {Injectable} from '@angular/core';
import {ResoursesService} from './resourses.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import ApiRoutes from '../global/apiRoutes.routes';
import {HarmfulHabit, PatientPh, PatientAuxiliary} from '../models/patient';
import {map, catchError} from 'rxjs/operators';
import {ApiResp} from '../models/apiResp';
import {PatientProblem, Disease, IMedicalConusltation, MedicalReevaluation} from '../models/patientConsultation';
import {MedicalConsultation} from 'src/app/models/patientConsultation';
import {Fitocannabinoides} from '../models/fitocannabinoides.model';
import {medicalConsultRoutes, medicalReevaluationRoutes} from '../global/apiRoutes.routes';
import {MedicalReevaluationModel} from '../models/medicalReevaluation.model';

@Injectable({
  providedIn: 'root'
})
export class PatientManagementService {

  constructor(
    public resourses: ResoursesService,
    private http: HttpClient
  ) {
  }


  public getHarmfulHabitList(): Observable<HarmfulHabit[]> {
    return this.http.get<ApiResp>(environment.apiUrl + ApiRoutes.getharmfulHabitList, {headers: this.resourses.getHeaders()})
      .pipe(
        map(resp => resp.data),
        catchError(error => this.resourses.handleError(error)));
  }

  public savePatientPh(patientData: any): Observable<PatientPh> {
    return this.http.post<ApiResp>(environment.apiUrl + ApiRoutes.savepatientPh, patientData, {headers: this.resourses.getHeaders()})
      .pipe(
        map(resp => {
          this.resourses.handleResponse(resp);
          return resp.data;
        }),
        catchError(error => this.resourses.handleError(error)));
  }

  public findPatientPhBypatientId(patientId: string): Observable<PatientPh> {
    return this.http.get<ApiResp>(
      environment.apiUrl + ApiRoutes.getPatientPhBypatientIdUrl(patientId), {headers: this.resourses.getHeaders()})
      .pipe(
        map(resp => resp.data),
        catchError(error => this.resourses.handleError(error)));
  }

  public findAllPatientProblems(): Observable<PatientProblem[]> {
    return this.http.get<ApiResp>(environment.apiUrl + ApiRoutes.findAllPatientProblems, {headers: this.resourses.getHeaders()})
      .pipe(
        map(resp => resp.data),
        catchError(error => this.resourses.handleError(error)));
  }

  public findAllBodySystems(): Observable<any[]> {
    return this.http.get<ApiResp>(environment.apiUrl + ApiRoutes.findAllBodySystems, {headers: this.resourses.getHeaders()})
      .pipe(
        map(resp => resp.data),
        catchError(error => this.resourses.handleError(error)));
  }

  public savePatientConsultation(pt: any): Observable<MedicalConsultation> {
    return this.http.post<ApiResp>(environment.apiUrl + ApiRoutes.savePatientConsultation, pt, {headers: this.resourses.getHeaders()})
      .pipe(
        map(resp => {
          this.resourses.handleResponse(resp);
          return resp.data;
        }),
        catchError(error => this.resourses.handleError(error)));
  }

  public getMedicalConsultationById(id: string): Observable<IMedicalConusltation> {
    return this.http.get<ApiResp>(environment.apiUrl + ApiRoutes.getMedicalConsultationById + `/${id}`, {headers: this.resourses.getHeaders()})
      .pipe(
        map(resp => resp.data),
        catchError(error => this.resourses.handleError(error)));
  }

  public findMedicalConsultationByIdPatient(idPatient: string): Observable<MedicalConsultation[]> {
    return this.http.get<ApiResp>
    (environment.apiUrl + ApiRoutes.medicalConsultationByIdPatientUrl(idPatient), {headers: this.resourses.getHeaders()})
      .pipe(
        map(resp => resp.data),
        catchError(error => this.resourses.handleError(error)));
  }

  public saveMedicalReevaluation(medicalReevaluation: any, routeUrl: string): Observable<any> {
    return this.http.post(environment.apiUrl + ApiRoutes.saveMedicalReevaluation(), medicalReevaluation, {headers: this.resourses.getHeaders()})
      .pipe(
        map(resp => this.resourses.handleResponse(resp, routeUrl)),
        catchError(error => this.resourses.handleError(error)));
  }

  public updatePatientPh(patientPh: any): Observable<PatientPh> {
    return this.http.put<ApiResp>(environment.apiUrl + ApiRoutes.updatePatientPh, patientPh, {headers: this.resourses.getHeaders()})
      .pipe(
        map(resp => {
          this.resourses.handleResponse(resp, null, null, 'Actualizado');
          return resp.data;
        }),
        catchError(error => this.resourses.handleError(error)));
  }

  public findFitocannabinoides(): Observable<Fitocannabinoides[]> {
    return this.http.get<ApiResp>(environment.apiUrl + medicalConsultRoutes.fitocannabinoidesGetAll, {headers: this.resourses.getHeaders()})
      .pipe(
        map(resp => resp.data),
        catchError(error => this.resourses.handleError(error)));
  }

  public downloadRecipe(): Observable<Fitocannabinoides[]> {
    return this.http.get<ApiResp>(environment.apiUrl + 'api/medicalConsultation/getConsultationRecipeFile/getAll', {headers: this.resourses.getHeaders()})
      .pipe(
        map(resp => resp.data),
        catchError(error => this.resourses.handleError(error)));
  }

  public getReevaluationList(idConsultation: string): Observable<MedicalReevaluationModel[]> {
    return this.http.get<ApiResp>(environment.apiUrl + medicalReevaluationRoutes.getByIdConsultation(idConsultation), {headers: this.resourses.getHeaders()})
      .pipe(
        map(resp => resp.data),
        catchError(error => this.resourses.handleError(error)));
  }
  
  public findPatientAuxiliaryByPatientId(patientId: string): Observable<PatientAuxiliary[]> {
    return this.http.get<ApiResp>(
      environment.apiUrl + ApiRoutes.getPatientAuxiliaryByIdPatient(patientId), {headers: this.resourses.getHeaders()})
      .pipe(
        map(resp => resp.data),
        catchError(error => this.resourses.handleError(error)));
  }

  public savePatientAuxiliary(patientAuxiliaryData: any): Observable<PatientAuxiliary> {
    return this.http.post<ApiResp>(environment.apiUrl + ApiRoutes.savePatientAuxiliary(), patientAuxiliaryData, {headers: this.resourses.getHeaders()})
      .pipe(
        map(resp => {
          this.resourses.handleResponse(resp);
          return resp.data;
        }),
        catchError(error => this.resourses.handleError(error)));
  }

  public updatePatientAuxiliary(patientAuxiliaryData: any): Observable<PatientAuxiliary> {
    return this.http.put<ApiResp>(environment.apiUrl + ApiRoutes.updatePatientAuxiliary(), patientAuxiliaryData, {headers: this.resourses.getHeaders()})
      .pipe(
        map(resp => {
          this.resourses.handleResponse(resp, null, null, 'Actualizado');
          return resp.data;
        }),
        catchError(error => this.resourses.handleError(error)));
  }

}
