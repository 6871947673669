import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { Patient } from 'src/app/models/patient';
import { PatientProblem, MedicalConsultation, BodyPart, Disease, ClinicalExamination, MedicalTreatment, BlesseDementiaScale, AnxietyScale, DepressionScale, EdmontonScale, ExamReason } from 'src/app/models/patientConsultation';
import { PatientManagementService } from 'src/app/services/patient-management.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { ConsultationAdmitionService } from 'src/app/services/consultation-admition.service';
import { ConsultationAdmition } from 'src/app/models/consultationAdmision.model';
import { DiseaseService } from 'src/app/services/disease.service';
import { Appointment } from 'src/app/models/appointment.interface';
import { ComplementaryExam } from 'src/app/models/complementaryExam.model';
import { Fitocannabinoides } from 'src/app/models/fitocannabinoides.model';
import { MatDialog } from '@angular/material/dialog';
import { CreateDiseaseModalComponent } from '../../../../data-mintenence/disease/create-disease-modal/create-disease-modal.component';
import { environment } from 'src/environments/environment';
// tslint:disable-next-line: max-line-length
import { RegisterPatietnProblemModalComponent } from 'src/app/pages/data-mintenence/patient-problem/register-patietn-problem-modal/register-patietn-problem-modal.component';
import { RegisterReevaluationModalComponent } from '../detail-consultation/register-reevaluation-modal/register-reevaluation-modal.component';
import { ExamReasonService } from '../../../../../services/exam-reason.service';


@Component({
  selector: 'app-attend-consultation',
  templateUrl: './attend-consultation.component.html',
  styleUrls: ['./attend-consultation.component.scss']
})
export class AttendConsultationComponent implements OnInit {

  @ViewChild('stepper') private myStepper: MatStepper;

  public firstFormGroup: FormGroup;
  public patient: Patient = new Object();
  public patientConsultation: MedicalConsultation = new MedicalConsultation();
  public patientProblems: PatientProblem[] = [];
  public boddySystems: BodyPart[] = [];
  public myControl = new FormControl();
  public filteredOptions: Observable<any[]>;
  public diseaseList: Disease[] = [];
  public diagnosedDisease: Disease;
  public consultationAdmitionList: ConsultationAdmition[] = [];

  public registerConsultation: boolean = false;
  public registerReevaluation: boolean = false;

  public currentAppointment: Appointment = new Appointment();

  public diseaseListSelected: any[] = [];
  public medicalTreatmentList: MedicalTreatment[] = [];
  public currentMedicalTreatment: MedicalTreatment = new MedicalTreatment();

  public complementaryExamList: ComplementaryExam[] = [];
  public complementaryExamItem: ComplementaryExam = new ComplementaryExam();
  public fitocannabinoides: Observable<Fitocannabinoides[]>;
  public patientConsultationImc: number = 0;
  public consultationType: string;
  public medicalConsultationList: MedicalConsultation[] = [];
  public clinicalExaminationSelected: any;
  public examReasonList: ExamReason[] = [];

  public idConsultation: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private patientManagamentSrv: PatientManagementService,
    private snackBar: MatSnackBar,
    private diseaseSrv: DiseaseService,
    private consultationAdmitionSrv: ConsultationAdmitionService,
    private dialog: MatDialog,
    private examReasonSrv: ExamReasonService
  ) {
    this.idConsultation = activatedRoute.snapshot.params.id;
    this.patient = this.patientManagamentSrv.resourses.getCurrentStorageValue('currentPatient');
    this.currentAppointment = this.patientManagamentSrv.resourses.getCurrentStorageValue('currentAppointment');
  }

  ngOnInit(): void {
    this.getConsultationAdmitionList();
    this.getAllPatientProblems();
    this.getAllBodySystems();
    this.getDiseaseList();
    this.findMedicalConsultationList();
    this.getExamReasonList();
    this.fitocannabinoides = this.patientManagamentSrv.findFitocannabinoides();

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  private _filter(value: any): any[] {
    const filterValue = value.toLowerCase();
    const founded = this.diseaseList.filter(option => option.name.toLowerCase().includes(filterValue));
    return founded;
  }

  private getAllPatientProblems(): void {
    this.patientManagamentSrv.findAllPatientProblems().subscribe(patientProblemList => {
      this.patientProblems = patientProblemList;
    });
  }

  private getConsultationAdmitionList(): void {
    if (this.patient._id) {
      this.consultationAdmitionSrv.findByIdPatient(this.patient._id).subscribe(resp => {
        this.consultationAdmitionList = resp;
      });
    }
  }

  private getAllBodySystems(): void {
    this.patientManagamentSrv.findAllBodySystems().subscribe(bodySystemList => {
      this.boddySystems = bodySystemList;
    });
  }

  public setImc(): void {
    this.patientConsultation.medicalEvaluation.clinicalExamination.imc =
      Number((this.patientConsultation.medicalEvaluation.clinicalExamination.peso /
        (this.patientConsultation.medicalEvaluation.clinicalExamination.talla *
          this.patientConsultation.medicalEvaluation.clinicalExamination.talla)).toFixed(2));
  }

  private getDiseaseList(): void {
    this.diseaseSrv.getDiseaseList().subscribe(diseaseList => {
      this.diseaseList = diseaseList;
      this._filter('');
    });
  }

  public setDicease(disease: Disease): void {
    if (!this.diseaseListSelected.includes(disease)) {
      this.diseaseListSelected.push(disease);
    } else {
      return;
    }
  }

  public deleteDisease(disease: Disease): void {
    const index = this.diseaseListSelected.indexOf(disease);
    if (index !== -1) {
      this.diseaseListSelected.splice(index, 1);
    }
  }

  public getExamReasonList(): void {
    this.examReasonSrv.findAll().subscribe(resp => {
      this.examReasonList = resp;
    });
  }

  public examItemChanged(): void {
    for (let i = 0; i < this.examReasonList.length; i++) {
      if (this.complementaryExamItem.name == this.examReasonList[i].name) {
        this.complementaryExamItem.description = this.examReasonList[i].description;
      }
    }
  }

  public addMedicalTreatment(medicalTreatment: MedicalTreatment): void {
    if (!medicalTreatment.viaAdministracion) {
      medicalTreatment.viaAdministracion = '';
    }
    if (!medicalTreatment.fitocannabinoides) {
      medicalTreatment.fitocannabinoides = '';
    }
    if (!medicalTreatment.ratio) {
      medicalTreatment.ratio = '';
    }
    if (!medicalTreatment.concentracion) {
      medicalTreatment.concentracion = '';
    }
    if (!medicalTreatment.frequency) {
      medicalTreatment.frequency = '';
    }

    if (!medicalTreatment.observations) {
      medicalTreatment.observations = '';
    }

    if (!medicalTreatment.conditions) {
      medicalTreatment.conditions = '';
    }

    if (medicalTreatment.viaAdministracion === 'Sublingual') {
      if (!medicalTreatment.amountPerDose) {
        this.openSnackBar('Si la via de administración es Sublingual, debe elegir la cantidad de gotas por dosis aplicada', 'cerrar');
        return;
      }
    }

    this.currentMedicalTreatment.description = `Asignado en consulta`;
    this.currentMedicalTreatment.createDate = new Date();
    this.currentMedicalTreatment.type = 'TRATAMIENDO EN CONSULTA';
    this.medicalTreatmentList.push(medicalTreatment);
    this.currentMedicalTreatment = new MedicalTreatment();

  }

  public deleteMedicalTreatament(medicalTreatament: MedicalTreatment) {
    const index = this.medicalTreatmentList.indexOf(medicalTreatament);
    if (index !== -1) {
      this.medicalTreatmentList.splice(index, 1);
    }
  }

  public saveNewPateintConsultation() {
    this.patientManagamentSrv.savePatientConsultation(this.patientConsultation).subscribe(patientConsultation => {
      window.close();
      // location.href = `${environment.apiUrl}api/medicalConsultation/getRecipe/consultation/${patientConsultation._id}`;
      // this.patientManagamentSrv.resourses.reloadPage(`/app/pacientes/perfilPaciente/${this.patient._id}/historialConsultas`);
    });
  }

  public setNewMedicalConsultation(admisionRegistered: ConsultationAdmition): void {
    this.clinicalExaminationSelected = admisionRegistered;
    this.patientConsultationImc = Number((admisionRegistered.peso / (admisionRegistered.talla * admisionRegistered.talla)).toFixed(2));
    const newClinicalEvaluation: ClinicalExamination = new ClinicalExamination();
    newClinicalEvaluation._id = admisionRegistered._id;
    newClinicalEvaluation.talla = admisionRegistered.talla;
    newClinicalEvaluation.peso = admisionRegistered.peso;
    newClinicalEvaluation.perimetroabdominal = admisionRegistered.perimetroabdominal;
    newClinicalEvaluation.satO2 = admisionRegistered.satO2;
    newClinicalEvaluation.pa = admisionRegistered.pa;
    newClinicalEvaluation.fr = admisionRegistered.fr;
    newClinicalEvaluation.fc = admisionRegistered.fc;
    newClinicalEvaluation.imc = this.patientConsultationImc;

    this.patientConsultation = new MedicalConsultation();
    this.patientConsultation.medicalEvaluation.clinicalExamination = newClinicalEvaluation;
    this.registerConsultation = true;

    this.patientConsultation.medicalEvaluation.blesseDementiaScale = new BlesseDementiaScale();
    this.patientConsultation.medicalEvaluation.anxietyScale = new AnxietyScale();
    this.patientConsultation.medicalEvaluation.depressionScale = new DepressionScale();
    this.patientConsultation.medicalEvaluation.edmontonScale = new EdmontonScale();
  }

  private setPatientProbles(patientProblems: PatientProblem[]): Promise<PatientProblem[]> {
    return new Promise((resolve, reject) => {
      const newPatientProblemsArray: PatientProblem[] = [];
      patientProblems.forEach((patientProblem, i) => {
        const newPatientProblem: PatientProblem = {
          value: patientProblem.value,
          description: patientProblem.description,
          name: patientProblem.name
        };
        newPatientProblemsArray.push(newPatientProblem);
      });
      resolve(newPatientProblemsArray);
    });
  }

  private setNewBodySystemLait(bodySystemList: BodyPart[]): Promise<BodyPart[]> {
    return new Promise(resolve => {
      const newBodySystemList: BodyPart[] = [];
      bodySystemList.forEach((bodySystem, i) => {
        const newBodySystem: BodyPart = {
          name: bodySystem.name,
          description: bodySystem.description,
          value: bodySystem.value
        };
        newBodySystemList.push(newBodySystem);
      });
      resolve(newBodySystemList);
    });
  }

  public async validateStepOne(): Promise<void> {
    if (this.patientConsultation.patientStory?.length > 10) {
      this.patientConsultation.patientProblems = await this.setPatientProbles(this.patientProblems);
      this.myStepper.next();
      return;
    } else {
      this.openSnackBar('El relato del paciente debe tener como minimo 10 caracteres', 'Cerrar');
      return;
    }
  }

  public async validateStepTwo(): Promise<any> {

    if (!this.patientConsultation.medicalEvaluation.anamnesis) {
      this.openSnackBar('Debe ingresar la anamnesis', 'cerrar');
      return;
    }

    this.myStepper.next();
    // this.patientConsultation.physicalExam.generalSummary = await this.setNewBodySystemLait(this.boddySystems);
  }

  public validateStepThree(): void {
    if (this.diseaseListSelected.length === 0) {
      this.openSnackBar('Debe asignar por lo menos una enfermedad diagnosticada', 'cerrar');
      return;
    }

    if (!this.patientConsultation.medicalDiagnostic.description) {
      this.openSnackBar('Debe agregar un detalle, descripcion o razón del diagnotisco dictaminado.', 'cerrar');
      return;
    }

    if (this.medicalTreatmentList.length === 0) {
      this.openSnackBar('Debe agregar como minimo un tratamiento para la enfermedad del paciente', 'cerrar');
      return;
    }

    // En esta parte viene la razon de consulta al momento de asignar la cita
    let founded = false;
    this.consultationAdmitionList.forEach(element => {
      if (element._id == this.idConsultation) {
        if (element.appointment == null) {
          this.openSnackBar('Debes registrar cita de consulta', 'cerrar');
          return;
        }
        this.patientConsultation.consultationReason = element.appointment.patientProblem;
        this.patientConsultation.idAppointment = element.appointment._id;
        founded = true;
      }
    });
    if (!founded) {
      this.openSnackBar('Debes registrar cita de consulta', 'cerrar');
      return;
    }
    this.patientConsultation.medicalDiagnostic.disease = this.diseaseListSelected;
    this.patientConsultation.medicalDiagnostic.medicalTreatment = this.medicalTreatmentList;
    this.patientConsultation.patient = this.patient._id;
    this.patientConsultation.complementaryExams = this.complementaryExamList;
    this.patientConsultation.doctor = this.patientManagamentSrv.resourses.getUser()._id;
    this.patientConsultation.createDate = new Date();
    this.myStepper.next();
  }

  public finalStep(): void {
    this.saveNewPateintConsultation();
  }

  public setComplementaryExamList(): void {
    this.complementaryExamList.push(this.complementaryExamItem);
    this.complementaryExamItem = new ComplementaryExam();
  }

  private openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  public addDiseaseDialog(): void {
    const dialogRef = this.dialog.open(CreateDiseaseModalComponent, {
      width: '450px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getDiseaseList();
    });
  }

  public addPatientProblemDialog(): void {
    const dialogRef = this.dialog.open(RegisterPatietnProblemModalComponent, {
      width: '450px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getAllPatientProblems();
    });
  }

  private findMedicalConsultationList(): void {
    this.patientManagamentSrv.findMedicalConsultationByIdPatient(this.patient._id).subscribe(medialConsultations => {
      this.medicalConsultationList = medialConsultations;
    });
  }

  public registerReevaluationModal(medicalConsultation: any): void {
    medicalConsultation.idClinicalExamination = this.clinicalExaminationSelected._id;

    const dialogRef = this.dialog.open(RegisterReevaluationModalComponent, {
      width: '900px',
      maxHeight: '85vh',
      data: medicalConsultation,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.registerMedicalReevaluation(result, medicalConsultation);
      }
    });
  }

  private registerMedicalReevaluation(medicalReevaluation: any, medicalConsultation: any): void {
    const routeUrl: string = `app/pacientes/perfilPaciente/${medicalConsultation.patient}/historialConsultas`;
    this.patientManagamentSrv.saveMedicalReevaluation(medicalReevaluation, routeUrl).subscribe();
  }
}
