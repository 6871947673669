import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResp } from '../../../models/apiResp';
import { map, catchError } from 'rxjs/operators';
import { ResoursesService } from 'src/app/services/resourses.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PatientManagementService } from 'src/app/services/patient-management.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public dashboardData: any = new Object();

  constructor(
    private http: HttpClient,
    private resources: ResoursesService,
    private patientmnagamte: PatientManagementService
  ) { }

  ngOnInit(): void {
    this.setDashboardData().subscribe(res => {
      this.dashboardData = res;
    });
  }

  public setDashboardData(): Observable<any> {
    return this.http.get<ApiResp>(environment.apiUrl + 'api/General/getDashboardData')
      .pipe(
        map(resp => resp.data),
        catchError(error => this.resources.handleError(error)));
  }

  generatePdf() {
    this.patientmnagamte.downloadRecipe().subscribe();
  }

}
