import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagesModule } from './pages/pages.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuicklinkModule } from 'ngx-quicklink';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AttendConsultationComponent } from './pages/patient/patient-management/patient-appointments/attend-consultation/attend-consultation.component';
import { MaterialModule } from './pages/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlesseDetailAccordionComponent } from './pages/patient/patient-management/patient-appointments/attend-consultation/blesse-detail-accordion/blesse-detail-accordion.component';
import { AnxietyDetailAccordionComponent } from './pages/patient/patient-management/patient-appointments/attend-consultation/anxiety-detail-accordion/anxiety-detail-accordion.component';
import { EdmontonDetailAccordionComponent } from './pages/patient/patient-management/patient-appointments/attend-consultation/edmonton-detail-accordion/edmonton-detail-accordion.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

export function momentAdapterFactory() {
  return adapterFactory(moment);
};

@NgModule({
  declarations: [
    AppComponent,
    AttendConsultationComponent,
    BlesseDetailAccordionComponent,
    AnxietyDetailAccordionComponent,
    EdmontonDetailAccordionComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    PagesModule,
    QuicklinkModule,
    BrowserAnimationsModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
    NgbModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
