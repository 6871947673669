import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { MaterialModule } from '../material/material.module';
import { DialogProgressBarComponent } from './dialog-progress-bar/dialog-progress-bar.component';
import { LoadingComponent } from './loading/loading.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { AlertComponent } from './alert/alert.component';



@NgModule({
  declarations: [ProgressBarComponent, DialogProgressBarComponent, LoadingComponent, PageHeaderComponent, AlertComponent],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    ProgressBarComponent,
    LoadingComponent,
    PageHeaderComponent
  ]
})
export class ComponentsModule { }
