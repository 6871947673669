import { Component, OnInit, Input } from '@angular/core';
import { EdmontonScale } from 'src/app/models/patientConsultation';

@Component({
  selector: 'app-edmonton-detail-accordion',
  templateUrl: './edmonton-detail-accordion.component.html',
  styleUrls: ['./edmonton-detail-accordion.component.scss']
})
export class EdmontonDetailAccordionComponent implements OnInit {

  @Input() i: number;
  @Input() edmontonScale: EdmontonScale;

  constructor() { }

  ngOnInit(): void {
  }

}
