import { Injectable } from '@angular/core';
import { ResoursesService } from 'src/app/services/resourses.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PatientProblem } from 'src/app/models/patientConsultation';
import { ApiResp } from '../models/apiResp';
import { environment } from '../../environments/environment';
import ApiRoutes from '../global/apiRoutes.routes';
import { catchError, map } from 'rxjs/operators';
import { patientProblemRoutes } from '../global/apiRoutes.routes';

@Injectable({
  providedIn: 'root'
})
export class PatientProblemService {

  constructor(
    private resources: ResoursesService,
    private http: HttpClient
  ) { }

  public findAll(): Observable<PatientProblem[]> {
    return this.http.get<ApiResp>(environment.apiUrl + ApiRoutes.getPatientProblems(), { headers: this.resources.getHeaders() })
      .pipe(
        map(resp => resp.data),
        catchError(error => this.resources.handleError(error)));
  }

  public save(patientProblem: any): Observable<any> {
    return this.http.post<ApiResp>(environment.apiUrl + ApiRoutes.savePatientProblem(), patientProblem, { headers: this.resources.getHeaders() })
      .pipe(
        map(resp => this.resources.handleResponse(resp)),
        catchError(error => this.resources.handleError(error)));
  }

  public delte(idPatientProblem: string): Observable<any> {
    return this.http.delete<ApiResp>(environment.apiUrl + patientProblemRoutes.delete(idPatientProblem), { headers: this.resources.getHeaders() })
      .pipe(
        map(resp => this.resources.handleResponse(resp, null, null, 'Eliminado')),
        catchError(error => this.resources.handleError(error)));
  }
}
