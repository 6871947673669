    <div class="panel-group mat-elevation-z10" [id]="'accordion_' + '_' +  (i + 1)" role="tablist" aria-multiselectable="true">
        <div class="panel panel-col-grey">
            <div class="panel-heading" role="tab" id="headingOne_17">
                <h4 class="panel-title"> 
                    <a role="button" 
                       data-toggle="collapse" 
                       [attr.data-parent]="'#accordion_' + '_' +  (i + 1)" 
                       [href]="'#' + 'collapseOne_' + '_' +  (i + 1)" 
                       aria-expanded="false" 
                       aria-controls="collapseOne_17" 
                       class="collapsed"> 
                        <i class="material-icons">perm_contact_calendar</i> 
                        <div class="row">
                        <span class="col-12 col-md-9 align-content-end"> Escala de demencia de blesse </span>
                        </div>
                    </a> 
                </h4>
            </div>
            <div [id]="'collapseOne_' + '_' +  (i + 1)" 
                 class="panel-collapse collapse in" 
                 role="tabpanel" 
                 aria-labelledby="headingOne_17" 
                 aria-expanded="false">
                <div class="panel-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <div class="card-tite h6"> Cambios en la ejecución de las actividades diarias </div>
                                </div>
                                <div class="col-12">
                                    <div class="scale-tite"> Incapacidad para realizar tareas domésticas </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="blesseDementiaScale.housework" name="housework" (selectionChange)="sumScores()">
                                            <mat-option value="1"> Total</mat-option>                                            
                                            <mat-option value="0.5"> Parcial</mat-option>
                                            <mat-option value="0"> Ninguna</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="scale-tite"> Incapacidad para el uso de pequeñas cantidades de dinero </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="blesseDementiaScale.smallAmount" name="smallAmount" (selectionChange)="sumScores()">
                                            <mat-option value="1"> Total</mat-option>                                            
                                            <mat-option value="0.5"> Parcial</mat-option>
                                            <mat-option value="0"> Ninguna</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="scale-tite"> Incapacidad para recordar listas cortas de elementos (por ejemplo, compras) </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="blesseDementiaScale.rememberItems" name="rememberItems" (selectionChange)="sumScores()">
                                            <mat-option value="1"> Total</mat-option>                                            
                                            <mat-option value="0.5"> Parcial</mat-option>
                                            <mat-option value="0"> Ninguna</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div> 
                                <div class="col-12">
                                    <div class="scale-tite"> Incapacidad para orientarse en casa </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="blesseDementiaScale.orientSelf" name="orientSelf" (selectionChange)="sumScores()">
                                            <mat-option value="1"> Total</mat-option>                                            
                                            <mat-option value="0.5"> Parcial</mat-option>
                                            <mat-option value="0"> Ninguna</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="scale-tite"> Incapacidad para orientarse en calles familiares </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="blesseDementiaScale.navigateStreet" name="navigateStreet" (selectionChange)="sumScores()">
                                            <mat-option value="1"> Total</mat-option>                                            
                                            <mat-option value="0.5"> Parcial</mat-option>
                                            <mat-option value="0"> Ninguna</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="scale-tite"> Incapacidad para valorar el entorno (por ejemplo, reconocer si está en casa o en el hospital, discriminar entre parientes, médicos
                                         y enfermeras, etc.) </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="blesseDementiaScale.valueEnvironment" name="valueEnvironment" (selectionChange)="sumScores()">
                                            <mat-option value="1"> Total</mat-option>                                            
                                            <mat-option value="0.5"> Parcial</mat-option>
                                            <mat-option value="0"> Ninguna</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="scale-tite"> Incapacidad para recordar hechos recientes (por ejemplo, visitas de parientes o amigos, etc.) </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="blesseDementiaScale.recallEvents" name="recallEvents" (selectionChange)="sumScores()">
                                            <mat-option value="1"> Total</mat-option>                                            
                                            <mat-option value="0.5"> Parcial</mat-option>
                                            <mat-option value="0"> Ninguna</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="scale-tite"> Tendencia a rememorar el pasado </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="blesseDementiaScale.recallPast" name="recallPast" (selectionChange)="sumScores()">
                                            <mat-option value="1"> Total</mat-option>                                            
                                            <mat-option value="0.5"> Parcial</mat-option>
                                            <mat-option value="0"> Ninguna</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="scale-tite"> Comer </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="blesseDementiaScale.eat" name="eat" (selectionChange)="sumScores()">
                                            <mat-option value="0"> Limpiamente, con los cubiertos adecuados</mat-option>
                                            <mat-option value="2"> Desaliñadamente, sólo con la cuchara</mat-option>
                                            <mat-option value="2"> Sólidos simples (galletas)</mat-option>
                                            <mat-option value="3"> Ha de ser alimentado</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="scale-tite"> Vestir </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="blesseDementiaScale.wear" name="wear" (selectionChange)="sumScores()">
                                            <mat-option value="0"> Se viste sin ayuda</mat-option>
                                            <mat-option value="2"> Fallos ocasionales (en el abotonamiento)</mat-option>
                                            <mat-option value="2"> Errores y olvidos frecuentes en la secuencia de vestirse</mat-option>
                                            <mat-option value="3"> Incapaz de vestirse</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="scale-tite"> Control de esfínteres </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="blesseDementiaScale.toiletTraining" name="toiletTraining" (selectionChange)="sumScores()">
                                            <mat-option value="0"> Normal</mat-option>
                                            <mat-option value="2"> Incontinencia urinaria ocasional</mat-option>
                                            <mat-option value="2"> Incontinencia urinaria frecuente</mat-option>
                                            <mat-option value="3"> Doble incontinencia</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12 mb-3">
                                    <div class="card-tite h6"> Cambios de personalidad y conducta (Sin cambios) </div>
                                </div>
                                <div class="col-12">
                                    <div class="scale-tite"> Retraimiento creciente </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="blesseDementiaScale.withdrawal" name="withdrawal" (selectionChange)="sumScores()">
                                            <mat-option value="0"> No</mat-option>
                                            <mat-option value="1"> Sí</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="scale-tite"> Egocentrismo aumentado </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="blesseDementiaScale.egocentrism" name="egocentrism" (selectionChange)="sumScores()">
                                            <mat-option value="0"> No</mat-option>
                                            <mat-option value="1"> Sí</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="scale-tite"> Pérdida de interés por los sentimientos de otros </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="blesseDementiaScale.interestOthers" name="interestOthers" (selectionChange)="sumScores()">
                                            <mat-option value="0"> No</mat-option>
                                            <mat-option value="1"> Sí</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="scale-tite"> Actividad embotada </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="blesseDementiaScale.dullActivity" name="dullActivity" (selectionChange)="sumScores()">
                                            <mat-option value="0"> No</mat-option>
                                            <mat-option value="1"> Sí</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="scale-tite"> Perturbación del control emocional (aumento de la susceptibilidad e irritabilidad) </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="blesseDementiaScale.emotionalControl" name="emotionalControl" (selectionChange)="sumScores()">
                                            <mat-option value="0"> No</mat-option>
                                            <mat-option value="1"> Sí</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="scale-tite"> Hilaridad inapropiada </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="blesseDementiaScale.inappropriateHilarity" name="inappropriateHilarity" (selectionChange)="sumScores()">
                                            <mat-option value="0"> No</mat-option>
                                            <mat-option value="1"> Sí</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="scale-tite"> Respuesta emocional disminuida </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="blesseDementiaScale.emotionalResponse" name="emotionalResponse" (selectionChange)="sumScores()">
                                            <mat-option value="0"> No</mat-option>
                                            <mat-option value="1"> Sí</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="scale-tite"> Indiscreciones sexuales (de aparición reciente) </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="blesseDementiaScale.sexualIndiscretions" name="sexualIndiscretions" (selectionChange)="sumScores()">
                                            <mat-option value="0"> No</mat-option>
                                            <mat-option value="1"> Sí</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="scale-tite"> Falta de interés en las aficiones habituales </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="blesseDementiaScale.interestHobbies" name="interestHobbies" (selectionChange)="sumScores()">
                                            <mat-option value="0"> No</mat-option>
                                            <mat-option value="1"> Sí</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="scale-tite"> Disminución de la iniciativa o apatía progresiva </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="blesseDementiaScale.progressiveApathy" name="progressiveApathy" (selectionChange)="sumScores()">
                                            <mat-option value="0"> No</mat-option>
                                            <mat-option value="1"> Sí</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="scale-tite"> Hiperactividad no justificada </div>
                                    <mat-form-field class="w100p">
                                        <mat-select [(ngModel)]="blesseDementiaScale.unjustifiedHyperactivity" name="unjustifiedHyperactivity" (selectionChange)="sumScores()">
                                            <mat-option value="0"> No</mat-option>
                                            <mat-option value="1"> Sí</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-12">
                                    <div class="sum-tite"> Puntajes: {{blesseDementiaScale.sum}} </div>
                                </div>
                            </div>
                        </div>                                                                   
                    </div>
                                        
                </div>
            </div>
        </div>
    </div>