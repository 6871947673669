import { Pipe, PipeTransform } from '@angular/core';
import { Appointment } from '../models/appointment.interface';

@Pipe({
  name: 'filterAppointment'
})
export class FilterAppointmentPipe implements PipeTransform {

  transform(value: Appointment[], arg: string[]): Appointment[] {

    const results: Appointment[] = [];

    for (const item of value) {
      if (item.patient.user.names.toLowerCase().indexOf(arg[0].toLowerCase()) > -1) {
        results.push(item);
      }
    }
    return results;
  }

}
