import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PatientProblemService } from 'src/app/services/patient-problem.service';

@Component({
  selector: 'app-register-patietn-problem-modal',
  templateUrl: './register-patietn-problem-modal.component.html',
  styleUrls: ['./register-patietn-problem-modal.component.scss']
})
export class RegisterPatietnProblemModalComponent implements OnInit {

  public form: any = {
    name: '',
    description: ''
  };

  constructor(
    public dialogRef: MatDialogRef<RegisterPatietnProblemModalComponent>,
    private patietProblemSrv: PatientProblemService
  ) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public savePatientProblem(): void {
    this.patietProblemSrv.save(this.form).subscribe(resp => {
      this.dialogRef.close();
    });
  }

}
