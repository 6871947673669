<div class="content">
    <h1 mat-dialog-title> Registrar enfermedad </h1>
    <div mat-dialog-content>
        <form  class="row">
            <div class="col-12">
                <mat-form-field class="example-full-width">
                    <mat-label>Nombre</mat-label>
                    <input matInput [(ngModel)]="formDisease.name"  autocomplete="off" name="name" id="name">
                </mat-form-field>
            </div>
            
            <div class="col-12">
                <mat-form-field class="example-full-width">
                    <mat-label>Descripción</mat-label>
                    <input matInput [(ngModel)]="formDisease.description"  autocomplete="off" name="description" id="description">
                </mat-form-field>
            </div>            
        </form>
    </div>
    <div mat-dialog-actions class="row justify-content-between">
        <button style="color: black;" mat-button (click)="onNoClick()" class="btn  btn-raised btn-sm bg-light waves-effect pointer">Cancelar</button>
        <button style="color: white;" mat-button (click)="saveDisease()" class="btn  btn-raised btn-sm bg-blue-grey waves-effect pointer" cdkFocusInitial>Guardar</button>
    </div>
</div>