import { Injectable, EventEmitter } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { User } from '../models/user';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
declare var $: any;


@Injectable({
  providedIn: 'root'
})
export class ResoursesService {

  public loading: boolean = false;

  constructor(
    public router: Router,
  ) {
    window.scroll(0, 0);
  }


  public handleResponse(resp: any, pageUrl?: string, idModal?: string, messageHeader?: string): void {
    Swal.fire(messageHeader ? messageHeader : 'Operación exitosa', resp.message, 'success');
    if (pageUrl) {
      this.reloadPage(pageUrl);
    }
    if (idModal) {
      $(`#${idModal}`).modal('hide');
    }
  }

  public handleError(error: HttpErrorResponse, pageUrl?: string, idModal?: string) {
    const errorMesage: string = error.error?.error?.description?.name  || error.error?.error?.name ||
      error.error?.error?.description?.errmsg  || error.error?.message || 'Error!';
    Swal.fire('Error!', errorMesage, 'error');
    if (error.status === 401) {
      this.router.navigate(['/login']);
      localStorage.clear();
    }
    return throwError(error);
  }

  public reloadPage(url: string): void {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url]));
  }


  public getDate(): string {
    const date = new Date();

    let month: string = JSON.stringify(date.getMonth() + 1);
    let day: string = JSON.stringify(date.getDate());

    if (month.length === 1) {
      month = '0' + month;
    }

    if (day.length === 1) {
      day = '0' + day;
    }
    return `${day}-${month}-${date.getFullYear()}`;
  }

  public getHeaders(): HttpHeaders {
    if (localStorage.getItem('userToken')) {
      return new HttpHeaders({
        token: localStorage.getItem('userToken'),
        'Content-Type': 'application/json'
      });
    }
  }

  public getCurrentStorageValue(valueName: string): any {
    if (localStorage.getItem(valueName)) {
      return JSON.parse(localStorage.getItem(valueName));
    } else {
      return {};
    }
  }

  public getUser(): User {
    if (localStorage.getItem('userLogged')) {
      return JSON.parse(localStorage.getItem('userLogged'));
    }
  }

  public showAlert(title: string, subtitle: string): Promise<boolean> {
    return new Promise(resolve => {
      Swal.fire({
        title,
        text: subtitle,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {

        if (result.value) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

}
