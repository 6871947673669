import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Appointment } from 'src/app/models/appointment.interface';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BankService } from 'src/app/services/bank.service'
import { Bank } from 'src/app/models/patientConsultation'

@Component({
  selector: 'app-register-payment',
  templateUrl: './register-payment.component.html',
  styleUrls: ['./register-payment.component.scss']
})
export class RegisterPaymentComponent implements OnInit {

  public paymentForm: FormGroup;
  public bankList: Bank[];

  constructor(
    public dialogRef: MatDialogRef<RegisterPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public appointmentData: Appointment,
    private builder: FormBuilder,
    private bankService: BankService
  ) {
    this.setPaymentForm();
    this.getBankList();
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private setPaymentForm(): void {
    this.paymentForm = this.builder.group({
      appointment: [this.appointmentData._id],
      paymentMethod: [null, Validators.required],
      operationCode: [null],
      value: [null, Validators.required],
      bankAccount: [null],
      registerDate: [null, Validators.required]
    });
  }

  public getBankList(): void {
    this.bankService.findAll().subscribe(resp => {
      this.bankList = resp;
    });
  }
}
