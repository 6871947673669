import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PagesComponent } from './pages.component';
import { LoginGuard } from '../guard/login.guard';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { AdminGuard } from '../guard/admin.guard';
import { MyPatientsComponent } from './my-patients/my-patients.component';


const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    canActivate: [LoginGuard],
    children: [
      {
        path: `usuarios`, canActivate: [AdminGuard], loadChildren: () =>
        import (`./user/user.module`).then( a => a.UserModule)
      },
      {
        path: `pacientes`, loadChildren: () =>
        import (`./patient/patients.module`).then( p => p.PatientsModule)
      },
      {
        path: `mantenimientosGenerales`, canActivate: [AdminGuard], loadChildren: () =>
        import(`./data-mintenence/data-mintenence.module`).then(dm => dm.DataMintenenceModule)
      },
      {
        path: `doctor`, loadChildren: () =>
        import(`./doctor/doctor.module`).then(d => d.DoctorModule)
      },
      {
        path: `consultas`, loadChildren: () =>
        import(`./appointment/appointments.module`).then(a => a.AppointmentsModule)
      },
      {
        path: `miPacientes`, component: MyPatientsComponent
      },
      {
        path: '', component: DashboardComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
