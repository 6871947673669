import { Component, OnInit, Inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IMedicalConusltation, MedicalTreatment, BlesseDementiaScale, AnxietyScale, DepressionScale, EdmontonScale } from 'src/app/models/patientConsultation';
import { ResoursesService } from 'src/app/services/resourses.service';
import { Observable } from 'rxjs';
import { Fitocannabinoides } from 'src/app/models/fitocannabinoides.model';
import { PatientManagementService } from 'src/app/services/patient-management.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { ListReevaluationComponent } from '../list-reevaluation/list-reevaluation.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-register-reevaluation-modal',
  templateUrl: './register-reevaluation-modal.component.html',
  styleUrls: ['./register-reevaluation-modal.component.scss']
})
export class RegisterReevaluationModalComponent implements OnInit {

  public reevaluationForm: FormGroup;
  public currentMedicalTreatment: MedicalTreatment = new MedicalTreatment();
  public medicalTreatmentList: MedicalTreatment[] = [];
  public fitocannabinoides: Observable<Fitocannabinoides[]>;
  public medicalConusltation: IMedicalConusltation = new Object();

  constructor(
    private activatedRoute: ActivatedRoute,
    private builder: FormBuilder,
    private resources: ResoursesService,
    private patientManagamentSrv: PatientManagementService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    this.setReevaluationForm();
  }

  ngOnInit(): void {
    this.fitocannabinoides = this.patientManagamentSrv.findFitocannabinoides();
  }

  onNoClick(): void {
    window.close();
  }

  public registerMedicalReevaluation(): void {
    const routeUrl: string = `app/pacientes/perfilPaciente/${this.medicalConusltation.patient._id}/historialConsultas`;
    this.patientManagamentSrv.saveMedicalReevaluation(this.reevaluationForm.value, routeUrl).subscribe(res => {
      Swal.fire({
        title: '',
        text: '¿Quieres generar una receta?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          this.reevaluationList(true);
        }
      });
    });
  }

  public reevaluationList(spread: boolean = false): void {
    const dialogData = {
      medicalConsultation: this.medicalConusltation._id,
      spread: spread
    }
    const dialogRef = this.dialog.open(ListReevaluationComponent, {
      width: '850px',
      maxHeight: '90vh',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  public addMedicalTreatment(medicalTreatment: MedicalTreatment): void {

    if (!medicalTreatment.viaAdministracion) {
      medicalTreatment.viaAdministracion = '';
    }
    if (!medicalTreatment.fitocannabinoides) {
      medicalTreatment.fitocannabinoides = '';
    }
    if (!medicalTreatment.ratio) {
      medicalTreatment.ratio = '';
    }
    if (!medicalTreatment.concentracion) {
      medicalTreatment.concentracion = '';
    }
    if (!medicalTreatment.frequency) {
      medicalTreatment.frequency = '';
    }

    if (!medicalTreatment.observations) {
      medicalTreatment.observations = '';
    }

    if (!medicalTreatment.conditions) {
      medicalTreatment.conditions = '';
    }

    if (medicalTreatment.viaAdministracion === 'Sublingual') {
      if (!medicalTreatment.amountPerDose) {
        this.openSnackBar('Si la via de administración es Sublingual, debe elegir la cantidad de gotas por dosis aplicada', 'cerrar');
        return;
      }
    }
    if (!this.medicalTreatmentList.includes(medicalTreatment)) {
      medicalTreatment.description = `Reevaluación ${this.resources.getDate()}`;
      medicalTreatment.createDate = new Date();
      this.currentMedicalTreatment = new MedicalTreatment();
      this.medicalTreatmentList.push(medicalTreatment);
      this.reevaluationForm.value.medicalTreatment = this.medicalTreatmentList;
    } else {
      return;
    }
  }

  public deleteMedicalTreatament(medicalTreatament: MedicalTreatment) {
    const index = this.medicalTreatmentList.indexOf(medicalTreatament);
    if (index !== -1) {
      this.medicalTreatmentList.splice(index, 1);
    }
  }

  private openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  private setReevaluationForm(): void {
    this.patientManagamentSrv.getMedicalConsultationById(this.activatedRoute.snapshot.params.id).subscribe(resp => {
      this.medicalConusltation = resp;
      this.reevaluationForm = this.builder.group({
        medicalConsultation: [this.medicalConusltation._id],
        description: [null, [Validators.required, Validators.minLength(10)]],
        createDate: new Date(),
        painScale: [null],
        blesseDementiaScale: new BlesseDementiaScale(),
        anxietyScale: new AnxietyScale(),
        depressionScale: new DepressionScale(),
        edmontonScale: new EdmontonScale(),
        medicalTreatment: [[]],
        idClinicalExamination: [this.medicalConusltation.idClinicalExamination ? this.medicalConusltation.idClinicalExamination : null],
        recomendations: [null]
      });
    });
  }

}
