import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import Swal from 'sweetalert2';
import { ResoursesService } from 'src/app/services/resourses.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private resources: ResoursesService) { }

  canActivate() {
    if (localStorage.getItem('userLogged')) {
      if (JSON.parse(localStorage.getItem('userLogged')).rol.name === 'Administrador') {
        return true;
      } else {
        Swal.fire('No autorizado', '', 'warning');
        return false;
      }
    } else {
      this.resources.router.navigate(['login']);
      return false;
    }
  }

}
