import { Injectable } from '@angular/core';
import { ResoursesService } from 'src/app/services/resourses.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConsultationAdmition } from '../models/consultationAdmision.model';
import { ApiResp } from '../models/apiResp';
import { environment } from '../../environments/environment';
import ApiRoutes from '../global/apiRoutes.routes';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConsultationAdmitionService {

  constructor(
    private resourses: ResoursesService,
    private http: HttpClient
  ) { }

  public save(consultationAdmition: any): Observable<any> {
    return this.http.post<ApiResp>(environment.apiUrl + ApiRoutes.saveConsultationAdmition(), consultationAdmition, { headers: this.resourses.getHeaders() })
      .pipe(
        map(resp => this.resourses.handleResponse(resp)),
        catchError(error => this.resourses.handleError(error)));
  }

  public findByIdPatient(idPatient: string): Observable<ConsultationAdmition[]> {
    return this.http.get<ApiResp>(environment.apiUrl + ApiRoutes.getConsultationAdmitionByIdPatient(idPatient), { headers: this.resourses.getHeaders() })
      .pipe(
        map(resp => resp.data),
        catchError(error => this.resourses.handleError(error)));
  }
}
