import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { ResoursesService } from 'src/app/services/resourses.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-noreevaluated-sidebar',
  templateUrl: './noreevaluated-sidebar.component.html',
  styleUrls: ['./noreevaluated-sidebar.component.scss']
})
export class NoreevaluatedSidebarComponent implements OnInit {

  public user: User = new Object();
  public dahboardData: any = new Object();

  constructor(
    public userSrv: UserService,
    public resources: ResoursesService,
    private http: HttpClient
  ) {
    if (localStorage.getItem('userLogged')) {
      this.user = JSON.parse(localStorage.getItem('userLogged'));
    }
  }

  ngOnInit() {}


}
