import { Component, OnInit } from '@angular/core';
import { User } from '../models/user';
import { PatientsService } from 'src/app/services/patients.service'
import { NoreevaluatedModalComponent } from 'src/app/pages/dashboard/noreevaluated-modal/noreevaluated-modal.component'
import { MatDialog } from '@angular/material/dialog';

declare function init_plugins();


@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {

  public user: User = new Object();
  public notEvaluatedExist: boolean = false;
  public patientsNotEvaluaged: any[] = [];

  constructor(
    private patientSrv: PatientsService,
    private dialog: MatDialog
    ) {
    window.scroll(0, 0);
    this.user = JSON.parse(localStorage.getItem(`userLogged`));
    patientSrv.getPatientsNotEvaluated(this.user._id).subscribe(res => {
      this.patientsNotEvaluaged = res;
      this.notEvaluatedExist = res.length > 0;
      // this.patientsNotEvaluated = res;
    })
  }

  ngOnInit(): void {
    init_plugins();
  }

  public openNotReevaluagedModal(): void {
    const dialogRef = this.dialog.open(NoreevaluatedModalComponent, {
      width: '320px',
      height: '90%',
      data: this.patientsNotEvaluaged
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
