import { Component, OnInit, Input, Inject } from '@angular/core';
import { MedicalReevaluation } from 'src/app/models/patientConsultation';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { PatientManagementService } from 'src/app/services/patient-management.service';
import { MedicalReevaluationModel } from 'src/app/models/medicalReevaluation.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-reevaluation',
  templateUrl: './list-reevaluation.component.html',
  styleUrls: ['./list-reevaluation.component.scss']
})
export class ListReevaluationComponent implements OnInit {

  public reevaluationList: MedicalReevaluationModel[] = [];
  public idMedicalConsultation: string;
  public spread: boolean;

  constructor(
    public dialogRef: MatDialogRef<ListReevaluationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private patientMngSrv: PatientManagementService
  ) {
    this.idMedicalConsultation = data.medicalConsultation;
    this.spread = data.spread;
  }


  ngOnInit(): void {
    this.setMedicalConsultationList();
  }

  private setMedicalConsultationList(): void {
    this.patientMngSrv.getReevaluationList(this.idMedicalConsultation).subscribe(resp => {
      this.reevaluationList = resp;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public download(reevaluation: any): void {
    location.href = `${environment.apiUrl}api/medicalConsultation/getRecipe/reevaluation/${reevaluation._id}`;
  }

  public sendReevaluationEmailToRecipe(): void {
    setTimeout(() => {
      Swal.fire('Documento enviado', `Se ha enviado de manera exitosa la receta al al paciente`, 'success');
    }, 1500);
  }

}
