import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetDocumentsPipe } from './get-documents.pipe';
import { FilterAppointmentPipe } from './filter-appointment.pipe';



@NgModule({
  declarations: [GetDocumentsPipe, FilterAppointmentPipe],
  imports: [
    CommonModule
  ],
  exports: [
    GetDocumentsPipe,
    FilterAppointmentPipe
  ]
})
export class PipesModule { }
