import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResoursesService } from 'src/app/services/resourses.service';
import { Observable } from 'rxjs';
import { ApiResp } from '../models/apiResp';
import { environment } from '../../environments/environment';
import { doctorRoutes } from '../global/apiRoutes.routes';
import { map, catchError } from 'rxjs/operators';
import { Doctor } from '../models/doctor.interface';
import { DoctorAvailability } from 'src/app/models/doctorAvailability.model';

@Injectable({
  providedIn: 'root'
})
export class DoctorService {

  constructor(
    private http: HttpClient,
    private resources: ResoursesService
  ) { }

  public getAll(): Observable<Doctor[]> {
    return this.http.get<ApiResp>(environment.apiUrl + doctorRoutes.getAll(), { headers: this.resources.getHeaders() })
      .pipe(
        map(resp => resp.data),
        catchError(error => this.resources.handleError(error)));
  }

  public getDoctorAvailabilityByDoctorId(idDoctor: string): Observable<DoctorAvailability[]> {
    return this.http.get<ApiResp>(environment.apiUrl + doctorRoutes.getDoctorAvailability(idDoctor), { headers: this.resources.getHeaders() })
      .pipe(
        map(resp => resp.data),
        catchError(error => this.resources.handleError(error)));
  }

  public saveDoctorAvailability(data: any): Observable<any> {
    return this.http.post<ApiResp>(environment.apiUrl + doctorRoutes.saveDoctorAvailability(), data, { headers: this.resources.getHeaders() })
      .pipe(
        map(resp => this.resources.handleResponse(resp, null, null, 'Creado')),
        catchError(error => this.resources.handleError(error)));
  }

  public deleteDoctorAvailability(id: string): Observable<any> {
    return this.http.delete<ApiResp>(environment.apiUrl + doctorRoutes.delete(id), { headers: this.resources.getHeaders() })
      .pipe(
        map(resp => this.resources.handleResponse(resp, null, null, 'Eliminado')),
        catchError(error => this.resources.handleError(error)));
  }

  public save(doctor: any): Observable<any> {
    return this.http.post<ApiResp>(environment.apiUrl + doctorRoutes.save, doctor, { headers: this.resources.getHeaders() })
      .pipe(
        map(resp => this.resources.handleResponse(resp, 'app/doctor/listaDoctores')),
        catchError(error => this.resources.handleError(error)));
  }

  public getBySpecialyId(id: string): Observable<Doctor[]> {
    return this.http.get<ApiResp>(environment.apiUrl + doctorRoutes.findBySpecialty(id), { headers: this.resources.getHeaders() })
      .pipe(
        map(resp => resp.data),
        catchError(error => this.resources.handleError(error)));
  }

  public getDoctorAvailabilityByDate(data: any): Observable<DoctorAvailability[]> {
    return this.http.post<ApiResp>(environment.apiUrl + doctorRoutes.getDoctorAvailabilityByDate, data, { headers: this.resources.getHeaders() })
      .pipe(
        map(resp => resp.data),
        catchError(error => this.resources.handleError(error)));
  }

  public findById(id: string): Observable<Doctor> {
    return this.http.get<ApiResp>(environment.apiUrl + doctorRoutes.findById(id), { headers: this.resources.getHeaders() }).
      pipe(
        map(resp => resp.data),
        catchError(error => this.resources.handleError(error)));
  }

  public update(data: any): Observable<any> {
    return this.http.put<ApiResp>(environment.apiUrl + doctorRoutes.aupdate, data, { headers: this.resources.getHeaders() }).
      pipe(
        map(resp => this.resources.handleResponse(resp, 'app/doctor/listaDoctores', null, 'Actualizado')),
        catchError(error => this.resources.handleError(error)));
  }

  public delete(id): Observable<any> {
    return this.http.delete<ApiResp>(environment.apiUrl + doctorRoutes.deleteById(id), { headers: this.resources.getHeaders() })
      .pipe(
        map(resp => this.resources.handleResponse(resp, 'app/doctor/listaDoctores', null, 'Eliminado')),
        catchError(error => this.resources.handleError(error)));
  }
}
