import { Component, OnInit, Input } from '@angular/core';
import { ResoursesService } from '../../../services/resourses.service';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user';
import { ValidateRolGuard } from 'src/app/guard/validate-rol.guard';
import { Observable } from 'rxjs';
import { ApiResp } from 'src/app/models/apiResp';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss'],

})
export class LeftSidebarComponent implements OnInit {

  @Input() user: User;
  public dashboardData: any = new Object();

  constructor(
    public resources: ResoursesService,
    private userService: UserService,
    public rol: ValidateRolGuard,
    private http: HttpClient
  ) {
  }
  ngOnInit(): void {
    this.setDashboardData().subscribe(res => {
      this.dashboardData = res;
    });
  }

  public setDashboardData(): Observable<any> {
    return this.http.get<ApiResp>(environment.apiUrl + 'api/General/getDashboardData')
      .pipe(
        map(resp => resp.data),
        catchError(error => this.resources.handleError(error)));
  }

  public userLogout(): void {
    this.userService.loggOut();
  }

}
