    <div class="panel-group mat-elevation-z10" [id]="'accordion_' + '_' +  (i + 1)" role="tablist" aria-multiselectable="true">
        <div class="panel panel-col-grey">
            <div class="panel-heading" role="tab" id="headingOne_17">
                <h4 class="panel-title"> 
                    <a role="button" 
                       data-toggle="collapse" 
                       [attr.data-parent]="'#accordion_' + '_' +  (i + 1)" 
                       [href]="'#' + 'collapseOne_' + '_' +  (i + 1)" 
                       aria-expanded="false" 
                       aria-controls="collapseOne_17" 
                       class="collapsed"> 
                        <i class="material-icons">perm_contact_calendar</i> 
                        <div class="row">
                        <span class="col-12 col-md-9 align-content-end"> Escala de ansiedad y depresión </span>
                        </div>
                    </a> 
                </h4>
            </div>
            <div [id]="'collapseOne_' + '_' +  (i + 1)" 
                 class="panel-collapse collapse in" 
                 role="tabpanel" 
                 aria-labelledby="headingOne_17" 
                 aria-expanded="false">
                <div class="panel-body">
                    <div class="card" style="padding: 10px 0px;">
                        <div class="header">
                            <h2>Escala de ansiedad</h2>
                        </div>
                        <div class="body">
                            <div class="row">
                                <div class="col-12">
                                    <mat-checkbox [(ngModel)]="anxietyScale.feelingExcited" name="feelingExcited" (change)="countAnxietyScale()">
                                        ¿Se ha sentido muy excitado, nervioso o en tensión?
                                    </mat-checkbox>
                                </div>
                                <div class="col-12">
                                    <mat-checkbox [(ngModel)]="anxietyScale.worried" name="worried" (change)="countAnxietyScale()">
                                        ¿Ha estado muy preocupado por algo?
                                    </mat-checkbox>
                                </div>
                                <div class="col-12">
                                    <mat-checkbox [(ngModel)]="anxietyScale.feelingIrritable" name="feelingIrritable" (change)="countAnxietyScale()">
                                        ¿Se ha sentido muy irritable?
                                    </mat-checkbox>
                                </div>
                                <div class="col-12">
                                    <mat-checkbox [(ngModel)]="anxietyScale.troubleRelaxing" name="troubleRelaxing" (change)="countAnxietyScale()">
                                        ¿Ha tenido dificultad para relajarse?
                                    </mat-checkbox>
                                </div>
                                <div class="col-12">
                                    <mat-checkbox [(ngModel)]="anxietyScale.difficultySleeping" name="difficultySleeping" (change)="countAnxietyScale()">
                                        ¿Ha dormido mal, ha tenido dificultades para dormir?
                                    </mat-checkbox>
                                </div>
                                <div class="col-12">
                                    <mat-checkbox [(ngModel)]="anxietyScale.headaches" name="headaches" (change)="countAnxietyScale()">
                                        ¿Ha tenido dolores de cabeza o nuca?
                                    </mat-checkbox>
                                </div>
                                <div class="col-12">
                                    <mat-checkbox [(ngModel)]="anxietyScale.symptoms" name="symptoms" (change)="countAnxietyScale()">
                                        ¿Ha tenido alguno de los siguientes síntomas: temblores, hormigueos, mareos, sudores, diarrea?
                                    </mat-checkbox>
                                </div>
                                <div class="col-12">
                                    <mat-checkbox [(ngModel)]="anxietyScale.concernedHealth" name="concernedHealth" (change)="countAnxietyScale()">
                                        ¿Ha estado preocupado por su salud?
                                    </mat-checkbox>
                                </div>
                                <div class="col-12">
                                    <mat-checkbox [(ngModel)]="anxietyScale.fallingAsleep" name="fallingAsleep" (change)="countAnxietyScale()">
                                        ¿Ha tenido alguna dificultad para conciliar el sueño, para quedarse dormido?
                                    </mat-checkbox>
                                </div>
                                <div class="col-12">
                                    <mat-checkbox [(ngModel)]="anxietyScale.lowEnergy" name="lowEnergy" (change)="countAnxietyScale()">
                                        ¿Se ha sentido con poca energía?
                                    </mat-checkbox>
                                </div>
                                <div class="col-12">
                                    <div class="card-tite">Resultado: {{anxietyScale.count}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" style="padding: 10px 0px;">
                        <div class="header">
                            <h2>Escala de depresión</h2>
                        </div>
                        <div class="body">
                            <div class="row">
                                <div class="col-12">
                                    <mat-checkbox [(ngModel)]="depressionScale.lostInterest" name="lostInterest" (change)="countDepressionScale()">
                                        ¿Ha perdido usted su interés por las cosas?
                                    </mat-checkbox>
                                </div>
                                <div class="col-12">
                                    <mat-checkbox [(ngModel)]="depressionScale.lostConfidence" name="lostConfidence" (change)="countDepressionScale()">
                                        ¿Ha perdido la confianza en sí mismo?
                                    </mat-checkbox>
                                </div>
                                <div class="col-12">
                                    <mat-checkbox [(ngModel)]="depressionScale.feelHopeless" name="feelHopeless" (change)="countDepressionScale()">
                                        ¿Se ha sentido usted desesperanzado, sin esperanzas?
                                    </mat-checkbox>
                                </div>
                                <div class="col-12">
                                    <mat-checkbox [(ngModel)]="depressionScale.difficultyConcentrating" name="difficultyConcentrating" (change)="countDepressionScale()">
                                        ¿Ha tenido dificultades para concentrarse?
                                    </mat-checkbox>
                                </div>
                                <div class="col-12">
                                    <mat-checkbox [(ngModel)]="depressionScale.lostWeight" name="lostWeight" (change)="countDepressionScale()">
                                        ¿Ha perdido peso (a causa de su falta de apetito)?
                                    </mat-checkbox>
                                </div>
                                <div class="col-12">
                                    <mat-checkbox [(ngModel)]="depressionScale.wakeupEarly" name="wakeupEarly" (change)="countDepressionScale()">
                                        ¿Se ha estado despertando demasiado temprano?
                                    </mat-checkbox>
                                </div>
                                <div class="col-12">
                                    <mat-checkbox [(ngModel)]="depressionScale.feelSluggish" name="feelSluggish" (change)="countDepressionScale()">
                                        ¿Se ha sentido usted enlentecido?
                                    </mat-checkbox>
                                </div>
                                <div class="col-12">
                                    <mat-checkbox [(ngModel)]="depressionScale.feelWorse" name="feelWorse" (change)="countDepressionScale()">
                                        ¿Cree usted que ha tenido tendencia a encontrarse peor por las mañanas?
                                    </mat-checkbox>
                                </div>
                                <div class="col-12">
                                    <div class="card-tite">Resultado: {{depressionScale.count}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>