import { Component, OnInit, Input, OnChanges } from '@angular/core';
import ProgressBarData from '../../../models/progressBarData';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, OnChanges {

  @Input() progress: ProgressBarData;

  public currentPreogress: number = 0;
  public title: string = '';

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.currentPreogress = this.progress.progress;
    this.title = this.progress.title;
  }

}
