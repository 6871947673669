import { Component, OnInit, Input } from '@angular/core';
import { BlesseDementiaScale } from 'src/app/models/patientConsultation';

@Component({
  selector: 'app-blesse-detail-accordion',
  templateUrl: './blesse-detail-accordion.component.html',
  styleUrls: ['./blesse-detail-accordion.component.scss']
})
export class BlesseDetailAccordionComponent implements OnInit {

  @Input() i: number;
  @Input() blesseDementiaScale: BlesseDementiaScale;

  constructor() {
  }

  ngOnInit(): void {
    this.blesseDementiaScale.sum = "0";
  }

  public sumScores(): void {
    let sum = 0;
    Object.entries(this.blesseDementiaScale).forEach(entry => {
      const [key, value] = entry;
      if (key != 'sum' && value) {
        sum += parseFloat(value);
      }
    });    
    this.blesseDementiaScale.sum = "" + sum;
  }
}
