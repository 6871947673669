import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResoursesService } from 'src/app/services/resourses.service';
import { Observable } from 'rxjs';
import { ApiResp } from '../models/apiResp';
import { environment } from 'src/environments/environment';
import ApiRoutes from '../global/apiRoutes.routes';
import { map, catchError } from 'rxjs/operators';
import { Disease } from 'src/app/models/patientConsultation';
import { diseaseRoutes } from '../global/apiRoutes.routes';

@Injectable({
  providedIn: 'root'
})
export class DiseaseService {

  constructor(
    private http: HttpClient,
    private resourses: ResoursesService
  ) { }

  public getDiseaseList(): Observable<any[]> {
    this.resourses.loading = true;
    return this.http.get<ApiResp>(environment.apiUrl + ApiRoutes.getDiseaseList, { headers: this.resourses.getHeaders() })
      .pipe(
        map(resp => {
          this.resourses.loading = false;
          return resp.data;
        }),
        catchError(error => this.resourses.handleError(error)));
  }

  public save(newDicease: any): Observable<Disease> {
    return this.http.post<ApiResp>(environment.apiUrl + ApiRoutes.saveDisease(), newDicease, { headers: this.resourses.getHeaders() })
      .pipe(
        map(resp => {
          this.resourses.handleResponse(resp);
          return resp.data;
        }),
        catchError(error => this.resourses.handleError(error)));
  }

  public delete(idDisease: string): Observable<any> {
    return this.http.delete(environment.apiUrl + diseaseRoutes.delete(idDisease), { headers: this.resourses.getHeaders() })
      .pipe(
        map(resp => this.resourses.handleResponse(resp, null, null, 'Eliminado')),
        catchError(error => this.resourses.handleError(error)));
  }

  public update(disease: Disease): Observable<any> {
    return this.http.put<ApiResp>(environment.apiUrl + diseaseRoutes.update(), disease, { headers: this.resourses.getHeaders() })
      .pipe(
        map(resp => this.resourses.handleResponse(resp)),
        catchError(error => this.resourses.handleError(error)));
  }
}
