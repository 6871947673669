<div class="container-fluid">
    <div class="block-header">
        <h2>Formulario de admisión de consulta</h2>
        <small class="text-muted"> <a>Registro de datos de admisión para ser atendidos por médico correspondiente</a> </small>
    </div>
  </div>
<div mat-dialog-content>
    <div class="row">
        <div class="col-12">
            <form class="row" [formGroup]="medicalEvaluation">

              <div class="col-12 col-md-4">
                <mat-form-field>
                  <mat-label> Talla(cm) </mat-label>
                  <input matInput
                        name="talla"
                        type="number"
                        step="0.01"
                        placeholder="0.00"
                        formControlName="talla">
                </mat-form-field>
              </div>

              <div class="col-12 col-md-4">
                <mat-form-field>
                  <mat-label> Peso(kg) </mat-label>
                  <input matInput
                         type="number"
                         step="0.01"
                         name="peso"
                         id="peso"
                         (ngModelChange)="setSomething($event)"
                         placeholder="0.00"
                         formControlName="peso">
                </mat-form-field>
              </div>


              <div class="col-12 col-md-4 mt-3">
                <label>  </label>
                <input class="w100p" type="text" [value]="'Índice de masa corporal: ' + (imc || 0)" disabled>
              </div>

              <div class="col-12 col-md-4">
                <mat-form-field>
                  <mat-label> satO2(%) </mat-label>
                  <input matInput
                         name="satO2"
                         type="number"
                         formControlName="satO2"
                         placeholder="0%">
                </mat-form-field>
              </div>

              <div class="col-12 col-md-4">
                <mat-form-field>
                  <mat-label> Frecuencia respiratoria(F.R.) </mat-label>
                  <input matInput
                         name="fr"
                         type="number"
                         formControlName="fr"
                         placeholder="0">
                </mat-form-field>
              </div>

              <div class="col-12 col-md-4">
                <mat-form-field>
                  <mat-label> Frecuencia cardíaca (F.R.) </mat-label>
                  <input matInput
                         name="fc"
                         type="number"
                         formControlName="fc" >
                </mat-form-field>
              </div>

              <div class="col-12 col-md-4">
                <mat-form-field>
                  <mat-label> Presión arterial(P.A) </mat-label>
                  <input matInput
                         name="pa"
                         formControlName="pa"
                         type="text"
                         placeholder="120/80">
                </mat-form-field>
              </div>
            </form>
        </div>
      </div>
</div>
<div class="col-12">
    <div mat-dialog-actions class="row justify-content-between">
        <button class="btn btn-raised btn-default waves-effect" mat-button (click)="onNoClick()"> <i class="zmdi zmdi-close"></i> Cancelar</button>
        <button class="btn btn-raised btn-primary waves-effect"
                mat-button
                (click)="medicalEvaluation.value.imc = imc"
                [mat-dialog-close]="medicalEvaluation.value"
                cdkFocusInitial>
            <i class="zmdi zmdi-floppy"></i>
            Registrar
        </button>
    </div>
</div>
