import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';
import { RightsidebarComponent } from './rightsidebar/rightsidebar.component';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../../pipes/pipes.module';
import { NoreevaluatedModalComponent } from './noreevaluated-modal/noreevaluated-modal.component';


@NgModule({
  declarations: [
    NotFoundComponent,
    DashboardComponent,
    LeftSidebarComponent,
    RightsidebarComponent,
    NoreevaluatedModalComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    RouterModule,
    PipesModule
  ],
  exports: [
    LeftSidebarComponent,
    RightsidebarComponent
  ]
})
export class DashboardModule { }
