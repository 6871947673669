import { environment } from 'src/environments/environment';

export default class ApiRoutes {

    public static login = 'api/User/Login';
    public static resetPassword = 'api/User/resetPassword';


    // roles
    public static getAllRoles = 'api/User/GetAllRoles';
    public static createUser = 'api/User/Insert';

    // Patients
    public static savePatient = 'api/Patient/Insert';
    public static getDiseaseList = 'api/patientManagement/Disease/findAll';
    public static getharmfulHabitList = 'api/patientManagement/harmfulHabit/findAll';
    public static savepatientPh = 'api/patientManagement/patientPh/save';
    public static findAllPatientProblems = 'api/patientManagement/patientProblem/findAll';
    public static findAllExamReasons = 'api/patientManagement/examReason/findAll';
    public static findAllBodySystems = 'api/patientManagement/bodySystem/findAll';
    public static savePatientConsultation = 'api/patientManagement/medicalConsultation/save';
    public static getMedicalConsultationById = 'api/patientManagement/medicalConsultation/getById';
    public static updatePatientPh = 'api/patientManagement/patientPh/update';

    public static getGetAllUser(from: number, limit: number): string {
        return `api/User/Getall?from=${from}&limit=${limit}`;
    }

    public static getUserByParams(param: string): string {
        return `api/User/FindByParams/${param}`;
    }

    public static updateUser(idUser: string): string {
        return `api/User/Update/${idUser}`;
    }

    public static getUserById(idUser: string): string {
        return `api/User/FindById/${idUser}`;
    }

    public static deleteUser(idUser: string): string {
        return `api/User/Delete/${idUser}`;
    }

    public static getPatients(from: number, limit: number): string {
        return `api/Patient/GetAll?from=${from}&limit=${limit}`;
    }

    public static findPatientByParams(searchParams: string): string {
        return `api/Patient/FindByParams/${searchParams}`;
    }

    public static findPatientById(idPatient: string): string {
        return `api/Patient/FindById/${idPatient}`;
    }

    public static getPatientsNotEvaluated(idDoctor: string): string {
        return `api/Patient/getPatientsNotEvaluated/${idDoctor}`;
    }

    public static deletePatient(idPatient: string): string {
        return `api/Patient/Delete/${idPatient}`;
    }

    public static getPatientPhBypatientIdUrl(idPatient: string): string {
        return `api/patientManagement/patientPh/findBypatientId/${idPatient}`;
    }

    public static getsendForgotPassUrl(userEmail: string): string {
        return `api/User/forgotPassword/${userEmail}`;
    }

    public static medicalConsultationByIdPatientUrl(idPatient: string): string {
        return `api/patientManagement/medicalConsultation/findByIdPatinet/${idPatient}`;
    }

    public static saveMedicalReevaluation(): string {
        return `api/patientManagement/medicalReevaluation/save`;
    }

    public static saveDisease(): string {
        return `api/patientManagement/disease/save`;
    }

    public static saveConsultationAdmition(): string {
        return `api/patientManagement/consultationAdmition/save`;
    }

    public static getConsultationAdmitionByIdPatient(idPatient: string): string {
        return `api/patientManagement/consultationAdmition/findByIdPatient/${idPatient}`;
    }

    public static getPatientProblems(): string {
        return `api/patientManagement/patientProblem/findAll`;
    }

    public static savePatientProblem(): string {
        return `api/patientManagement/patientProblem/save`;
    }

    public static getExamReasons(): string {
        return `api/patientManagement/examReason/findAll`;
    }

    public static saveExamReason(): string {
        return `api/patientManagement/examReason/save`;
    }

    public static getMedications(): string {
        return `api/patientManagement/medication/findAll`;
    }

    public static saveMedication(): string {
        return `api/patientManagement/medication/save`;
    }

    public static getBanks(): string {
        return `api/patientManagement/bank/findAll`;
    }

    public static saveBank(): string {
        return `api/patientManagement/bank/save`;
    }

    public static getPatientAuxiliaryByIdPatient(idPatient: string): string {
        return `api/patientManagement/patientAuxiliary/findByPatientId/${idPatient}`;
    }

    public static savePatientAuxiliary(): string {
        return `api/patientManagement/patientAuxiliary/save`;
    }
    
    public static updatePatientAuxiliary(): string {
        return `api/patientManagement/patientAuxiliary/update`;
    }
}


export const diseaseRoutes = {
    delete(idDisease: string): string {
        return `api/patientManagement/disease/delete/${idDisease}`;
    },
    update(): string {
        return `api/patientManagement/disease/update`;
    }
};

export const patientProblemRoutes = {
    delete(idPatientProblem: string): string {
        return `api/patientManagement/patientProblem/delete/${idPatientProblem}`;
    }
};

export const examReasonRoutes = {
    delete(idExamReason: string): string {
        return `api/patientManagement/examReason/delete/${idExamReason}`;
    }
};

export const medicationRoutes = {
    delete(idMedication: string): string {
        return `api/patientManagement/medication/delete/${idMedication}`;
    }
};

export const bankRoutes = {
    delete(idBank: string): string {
        return `api/patientManagement/bank/delete/${idBank}`;
    }
};

export const harmfulHabistRoutes = {
    findAll(): string {
        return `api/patientManagement/harmfulHabit/findAll`;
    },
    save(): string {
        return `api/patientManagement/harmfulHabit/save`;
    },
    delete(idHarmfulHabit: string) {
        return `api/patientManagement/harmfulHabit/deleteById/${idHarmfulHabit}`;
    }
};

export const patientRoutes = {
    update(): string {
        return `api/Patient/update`;
    }
};

export const doctorRoutes = {
    getAll(): string {
        return 'api/doctor/getAll';
    },
    getDoctorAvailability(idDoctor: string): string {
        return `api/doctor/doctorAvailability/getByIdDoctor/${idDoctor}`;
    },
    saveDoctorAvailability(): string {
        return `api/doctor/doctorAvailability/save`;
    },
    delete(id: string): string {
        return `api/doctor/doctorAvailability/delete/${id}`;
    },
    findById(id: string): string {
        return `api/doctor/getById/${id}`;
    },
    deleteById(id: string): string {
        return `api/doctor/delete/${id}`;
    },
    save: 'api/doctor/save',
    aupdate: `api/doctor/update`,
    findBySpecialty(id: string): string {
        return `api/doctor/findBySpecialty/${id}`;
    },
    getDoctorAvailabilityByDate: `api/doctor/doctorAvailability/getCurrent`,
};

export const medicalSpecialityRoutes = {
    save: `api/doctor/medicalSpeciality/save`,
    getAll: `api/doctor/medicalSpeciality/getAll`,
    delete(id: string): string {
        return `api/doctor/medicalSpeciality/delete/${id}`;
    },
    update(id: string): string {
        return `api/doctor/medicalSpeciality/update/${id}`;
    }
};


export const appoinmentsRoutes = {
    save: `api/appointments/save`,
    getAll: `api/appointments/getAll`,
    updateStatus: `api/appointments/updateStatus`,
    update: `api/appointments/update`,
    registerPayment: `api/appointments/payment/save`,
    getTodayAppointmets: `api/appointments/getAppointmentsToday`,
    getById(id: string): string {
        return `api/appointments/getById/${id}`;
    },
    getToday(id: string): string {
        return `api/appointments/getTodayByIdDoctor/${id}`;
    },
    getByDoctor(id: string): string {
        return `api/appointments/getByIdDoctor/${id}`;
    },
    getAppointmentsByDate(date: string): string {
        return `api/appointments/getAppointmentsByDate/${date}`;
    },
    delete(id: string): string {
        return `api/appointments/delete/${id}`;
    }
};

export const generalRoutes = {
    getDocumentTuypes: 'api/General/documentType/find'
};

export const medicalConsultRoutes = {
    fitocannabinoidesGetAll: 'api/medicalConsultation/fitocannabinoides/getAll'
};

export const medicalReevaluationRoutes = {
    getByIdConsultation(id: string): string {
        return `api/patientManagement/medicalReevaluation/getByIdConsultation/${id}`;
    }
};

export const maritalStatusRoutes = {
    getAll: `api/User/maritalStatus`
};


export const appointmentStatusRoutes = {
    getAll: `${environment.apiUrl}api/appointment-status`
};

export const appointmentTypeRoutes = {
    getAll: `${environment.apiUrl}api/appointment-types`
};
