<aside id="rightsidebar" class="right-sidebar">
    <ul class="nav nav-tabs tab-nav-right" role="tablist">
        <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#settings"></a></li>

    </ul>
    <div class="tab-content">
        <div class="card pt-1 m-1">
            <div class="header text-center">
                <span class="h4"> {{user.names + ' ' + user.surenames | uppercase}}  </span>
            </div>
            <div class="body">
                <div class="row">
                    <div class="col-12">
                        <div class="row justify-content-center">
                            <img *ngIf="user.image" [src]="user.image | getDocuments: 'userImage'" alt="user" class="img-thumbnail img-fluid">
                            <img *ngIf="!user.image && user.sex === 'FEMENINO'" src="assets/images/patients/random-avatar3.jpg" alt="user" class="img-thumbnail img-fluid">
                            <img *ngIf="!user.image && user.sex === 'MASCULINO'" src="assets/images/patients/random-avatar1.jpg" alt="user" class="img-thumbnail img-fluid">
                        </div>
                    </div>
                    <div class="col-12" *ngFor="">
                    </div>
                    <div class="col-12">
                        <button (click)="userSrv.loggOut()" type="button" class="btn btn-raised btn-default waves-effect btn-block">CERRAR SESIÓN</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</aside>