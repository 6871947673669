import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { NotFoundComponent } from './pages/dashboard/not-found/not-found.component';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { AttendConsultationComponent } from './pages/patient/patient-management/patient-appointments/attend-consultation/attend-consultation.component'

const routes: Routes = [
  {
    path: 'login', loadChildren: () =>
    import('./pages/authentication/authentication.module').then(a => a.AuthenticationModule)
  },
  {
    path: 'app', loadChildren: () =>
    import('./pages/pages.module').then(a => a.PagesModule)
  },
  {
    path: 'attenderConsulta/:id', component: AttendConsultationComponent
  },
  {
    path: '', redirectTo: 'app', pathMatch: 'full'
  },
  {
    path: `**`, component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, enableTracing: false, preloadingStrategy: QuicklinkStrategy})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
