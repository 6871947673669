import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-dialog-progress-bar',
  templateUrl: './dialog-progress-bar.component.html',
  styleUrls: ['./dialog-progress-bar.component.scss']
})
export class DialogProgressBarComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogProgressBarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

}
