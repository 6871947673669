import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatientManagementService } from 'src/app/services/patient-management.service'
@Component({
  selector: 'app-noreevaluated-modal',
  templateUrl: './noreevaluated-modal.component.html',
  styleUrls: ['./noreevaluated-modal.component.scss']
})
export class NoreevaluatedModalComponent {

  public notReevaluatedPatients: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<NoreevaluatedModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public patientManagamentSrv: PatientManagementService
  ) {
    this.notReevaluatedPatients = data;
  }

  ngOnInit(): void {
    
  }

  public patientDetail(idPaient): void {
    this.patientManagamentSrv.resourses.reloadPage(`/app/pacientes/perfilPaciente/${idPaient}/historialConsultas`);
    this.dialogRef.close();
  }
}
