import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { ResoursesService } from './resourses.service';
import { Observable } from 'rxjs';
import { ApiResp } from '../models/apiResp';
import { environment } from '../../environments/environment';
import ApiRoutes from '../global/apiRoutes.routes';
import { catchError, map } from 'rxjs/operators';
import { Patient } from '../models/patient';
import { patientRoutes } from '../global/apiRoutes.routes';

@Injectable({
  providedIn: 'root'
})
export class PatientsService {

  constructor(
    private http: HttpClient,
    public resourses: ResoursesService
  ) { }


  public getAll(from: number, limit: number): Observable<ApiResp> {
    return this.http.get<ApiResp>(environment.apiUrl + ApiRoutes.getPatients(from, limit), { headers: this.resourses.getHeaders() })
      .pipe(
        catchError(error => this.resourses.handleError(error))
      );
  }


  public save(patientData: any): Observable<HttpEvent<any>> {

    const req = new HttpRequest('POST', environment.apiUrl + ApiRoutes.savePatient, patientData, {
      headers: this.resourses.getHeaders(),
      reportProgress: true
    });

    return this.http.request(req).pipe();
  }

  public findByParams(params: string): Observable<Patient[]> {
    return this.http.get<ApiResp>(environment.apiUrl + ApiRoutes.findPatientByParams(params), { headers: this.resourses.getHeaders() })
      .pipe(
        map(resp => resp.data),
        catchError(error => this.resourses.handleError(error)));
  }

  public findById(idPatient: string): Observable<Patient> {
    return this.http.get<ApiResp>(environment.apiUrl + ApiRoutes.findPatientById(idPatient), { headers: this.resourses.getHeaders() })
      .pipe(
        map(resp => resp.data),
        catchError(error => this.resourses.handleError(error)));
  }

  public getPatientsNotEvaluated(idDoctor: string): Observable<Patient[]> {
    return this.http.get<ApiResp>(environment.apiUrl + ApiRoutes.getPatientsNotEvaluated(idDoctor), { headers: this.resourses.getHeaders() })
      .pipe(
        map(resp => resp.data),
        catchError(error => this.resourses.handleError(error)));
  }

  public update(patient: any, pagueUrl: string): Observable<any> {
    return this.http.put<ApiResp>(environment.apiUrl + patientRoutes.update(), patient, {headers: this.resourses.getHeaders()})
    .pipe(
      map(resp => this.resourses.handleResponse(resp, pagueUrl, null, 'Actualizado')),
      catchError(error => this.resourses.handleError(error)));
  }
  
  public delete(idPatient: string): Observable<any> {
    return this.http.delete(environment.apiUrl + ApiRoutes.deletePatient(idPatient), { headers: this.resourses.getHeaders() })
      .pipe(
        map(res => this.resourses.handleResponse(res)),
        catchError(error => this.resourses.handleError(error)));
  }
}
