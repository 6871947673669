<div class="header">
    <h2>
        <i class="zmdi zmdi-calendar-note"></i> Reevaluación medica
    </h2>
</div>
<div class="body">
    <div class="row">
        <div class="col-12">
            <form [formGroup]="reevaluationForm" class="row">

                <div class="col-12">
                    <h3>
                        1. ¿Presenta dolor al momento de atender la reevaluación?
                    </h3>
                    <mat-form-field class="w100p">
                        <mat-label> Escala de dolor </mat-label>
                        <mat-select formControlName="painScale">
                            <mat-option value="1">1</mat-option>
                            <mat-option value="2">2</mat-option>
                            <mat-option value="3">3</mat-option>
                            <mat-option value="4">4</mat-option>
                            <mat-option value="5">5</mat-option>
                            <mat-option value="6">6</mat-option>
                            <mat-option value="7">7</mat-option>
                            <mat-option value="8">8</mat-option>
                            <mat-option value="9">9</mat-option>
                            <mat-option value="10">10</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-12">
                    <h3>
                        2. ¿Presenta ansiedad al momento de atender la reevaluación?
                    </h3>
                    <mat-form-field class="w100p">
                        <mat-label> Escala de ansiedad </mat-label>
                        <mat-select formControlName="solicitudeScale">
                            <mat-option value="1">1</mat-option>
                            <mat-option value="2">2</mat-option>
                            <mat-option value="3">3</mat-option>
                            <mat-option value="4">4</mat-option>
                            <mat-option value="5">5</mat-option>
                            <mat-option value="6">6</mat-option>
                            <mat-option value="7">7</mat-option>
                            <mat-option value="8">8</mat-option>
                            <mat-option value="9">9</mat-option>
                            <mat-option value="10">10</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-12">
                    <!-- Exámenes auxiliares e informes -->
                    <app-blesse-detail-accordion [blesseDementiaScale]="reevaluationForm.value.blesseDementiaScale" [i]="0">
                    </app-blesse-detail-accordion>
                    <!-- Exámenes auxiliares e informes -->

                    <!-- Escala de ansiedad y depresión -->
                    <app-anxiety-detail-accordion [anxietyScale]="reevaluationForm.value.anxietyScale" [depressionScale]="reevaluationForm.value.depressionScale" [i]="1">
                    </app-anxiety-detail-accordion>
                    <!-- Escala de ansiedad y depresión -->

                    <!-- Escala de edmonton -->
                    <app-edmonton-detail-accordion [edmontonScale]="reevaluationForm.value.edmontonScale" [i]="2">
                    </app-edmonton-detail-accordion>
                    <!-- Escala de edmonton -->
                </div>

                <div class="form-group col-12 mt-3">
                    <h3>
                        3. Ingrese un resumen de revaluación
                    </h3>

                    <div class="col-12">
                        <div class="card-tite h6"> CONVULSIONES </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <mat-form-field class="example-full-width w100p">
                                    <mat-label>Cantidad</mat-label>
                                    <input [(ngModel)]="reevaluationForm.value.seizuresQuantity" matInput name="seizuresQuantity">
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-md-6">
                                <mat-form-field class="example-full-width w100p">
                                    <mat-label>Frecuencia</mat-label>
                                    <input [(ngModel)]="reevaluationForm.value.seizuresFrequency" matInput name="seizuresFrequency">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="card-tite h6"> Comentarios </div>
                        <div class="row">
                            <div class="col-12 col-md-12">
                                <mat-form-field class="example-full-width">
                                    <textarea rows="4" [(ngModel)]="reevaluationForm.value.comment" matInput name="comment"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <mat-form-field class="example-full-width">
                            <mat-label>Reevaluación medica</mat-label>
                            <textarea rows="4" name="description" formControlName="description" matInput placeholder="Detalle específicamente a conclusión de la reevaluación medica">
                                </textarea>
                            <mat-error *ngIf="reevaluationForm.controls['description'].errors?.minlength">
                                La descripción de la reevaluación medica debe tener mas de 10 caracteres
                            </mat-error>
                        </mat-form-field>
                    </div>

                </div>

                <div class="form-group col-12 mt-3">
                    <h3>
                        4. Recomendaciones
                    </h3>
                    <mat-form-field class="example-full-width">
                        <mat-label>Recomendaciones después de reevaluación </mat-label>
                        <textarea rows="4" name="description" formControlName="recomendations" matInput>
                            </textarea>
                    </mat-form-field>
                </div>

            </form>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <h2 class="h2">
                <i class="zmdi zmdi-floppy"></i> Registrar un nuevo tratamiento
            </h2>
        </div>
    </div>

    <div class="row mt-3 mb-5">
        <div class="col-12 col-md-8">
            <mat-form-field class="w100p">
                <mat-label>Seleccionar una vía de administración</mat-label>
                <mat-select [(value)]="currentMedicalTreatment.viaAdministracion">
                    <mat-option value="Sublingual">Sublingual</mat-option>
                    <mat-option value="Vaporizada">Vaporizada</mat-option>
                    <mat-option value="Topica">Topica</mat-option>
                    <mat-option value="Vaginal">Vaginal</mat-option>
                    <mat-option value="Rectal">Rectal</mat-option>
                    <mat-option value="Oral">Oral</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-12 col-md-4">
            <mat-form-field class="w100p">
                <mat-label>Fitocannabinoides</mat-label>
                <mat-select [(value)]="currentMedicalTreatment.fitocannabinoides">
                    <mat-option *ngFor="let ft of fitocannabinoides | async" [value]="ft.name"> {{ ft.name }} </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <!-- <div class="col-12 col-md-4">
            <mat-form-field class="w100p">
                <mat-label>Ratio</mat-label>
                <mat-select [(value)]="currentMedicalTreatment.ratio">
                    <mat-option value="1/1">1/1</mat-option>
                    <mat-option value="2/1">2/1</mat-option>
                    <mat-option value="5/1">5/1</mat-option>
                    <mat-option value="10/1">10/1</mat-option>
                    <mat-option value="20/1">20/1</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-12 col-md-4">
            <mat-form-field class="w100p">
                <mat-label>Concentracón</mat-label>
                <mat-select [(value)]="currentMedicalTreatment.concentracion">
                    <mat-option value="3%">3%</mat-option>
                    <mat-option value="5%">5%</mat-option>
                    <mat-option value="10%">10%</mat-option>
                    <mat-option value="20%">20%</mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->

        <div class="col-12 col-md-4">
            <mat-form-field class="example-full-width">
                <mat-label>Ratio</mat-label>
                <input [(ngModel)]="currentMedicalTreatment.ratio" matInput>
            </mat-form-field>
        </div>

        <div class="col-12 col-md-4">
            <mat-form-field class="example-full-width">
                <mat-label>Concentración</mat-label>
                <input [(ngModel)]="currentMedicalTreatment.concentracion" matInput>
            </mat-form-field>
        </div>

        <div class="col-12 col-md-4">
            <mat-form-field class="w100p">
                <mat-label>Frecuencia</mat-label>
                <mat-select [(value)]="currentMedicalTreatment.frequency">
                    <mat-option value="1">1</mat-option>
                    <mat-option value="2">2</mat-option>
                    <mat-option value="3">3</mat-option>
                    <mat-option value="4">4</mat-option>
                    <mat-option value="5">5</mat-option>
                    <mat-option value="6">6</mat-option>
                    <mat-option value="7">7</mat-option>
                    <mat-option value="8">8</mat-option>
                    <mat-option value="9">9</mat-option>
                    <mat-option value="10">10</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-12 col-md-4" *ngIf="currentMedicalTreatment.viaAdministracion === 'Sublingual'">
            <mat-form-field class="w100p">
                <mat-label>Cantidad (gotas en dosis)</mat-label>
                <input [(ngModel)]="currentMedicalTreatment.amountPerDose" type="number" matInput>
                <!-- <mat-select [(value)]="currentMedicalTreatment.amountPerDose">
                    <mat-option value="1">1</mat-option>
                    <mat-option value="2">2</mat-option>
                    <mat-option value="3">3</mat-option>
                    <mat-option value="4">4</mat-option>
                    <mat-option value="5">5</mat-option>
                    <mat-option value="6">6</mat-option>
                    <mat-option value="7">7</mat-option>
                    <mat-option value="8">8</mat-option>
                    <mat-option value="9">9</mat-option>
                    <mat-option value="10">10</mat-option>
                </mat-select> -->
            </mat-form-field>
        </div>

        <div class="col-12">
            <mat-form-field class="example-full-width">
                <mat-label>Observaciones</mat-label>
                <textarea [(ngModel)]="currentMedicalTreatment.observations" matInput rows="3">  </textarea>
            </mat-form-field>
        </div>

        <div class="col-12">
            <mat-form-field class="example-full-width">
                <mat-label>Condiciones</mat-label>
                <textarea [(ngModel)]="currentMedicalTreatment.conditions" matInput rows="3"> </textarea>
            </mat-form-field>
        </div>



        <div class="col-12 col-md-3">
            <button type="button" (click)="addMedicalTreatment(currentMedicalTreatment)" class="btn c-white btn-raised bg-teal btn-block btn-sm waves-effect">
        <i class="zmdi zmdi-collection-plus"></i>
        Agregar
      </button>

        </div>

        <div class="col-12 col-md-12 mt-4" *ngFor="let item of medicalTreatmentList; let i = index">
            <div class="row justify-content-between">
                <span class="h5"> Tratamiento {{ i + 1 }} </span>
                <button (click)="deleteMedicalTreatament(item)" class="btn  btn-raised bg-teal c-white btn-sm waves-effect pointer m-1" mat-button>
          <i class="pointer zmdi zmdi-close"></i>
        </button>
            </div>
            <div class="row mt-4 custom-border">
                <div class="col-12 col-md-4">
                    <span> V. de administración. </span>
                    <input style="width: 100%;" [value]="item.viaAdministracion" type="text" disabled>
                </div>
                <div class="col-12 col-md-4">
                    <span> Fitocannabinoides. </span>
                    <input style="width: 100%" [value]="item.fitocannabinoides" type="text" disabled>
                </div>
                <div class="col-12 col-md-4">
                    <span> Ratio </span>
                    <input style="width: 100%" [value]="item.ratio" type="text" disabled>
                </div>
                <div class="col-12 col-md-4">
                    <span> Concentración </span>
                    <input style="width: 100%" [value]="item.concentracion" type="text" disabled>
                </div>

                <div class="col-12 col-md-4">
                    <span> Frecuencia </span>
                    <input style="width: 100%" [value]="item.concentracion" type="text" disabled>
                </div>

                <div class="col-12 col-md-4" *ngIf="item.viaAdministracion === 'Sublingual'">
                    <span> Gotas por día </span>
                    <input style="width: 100%" [value]="item.concentracion" type="text" disabled>
                </div>

                <div class="col-12">
                    <span> Observaciones </span>
                    <textarea style="width: 100%" [value]="item.observations" type="text" disabled> </textarea>
                </div>

                <div class="col-12">
                    <span> Condiciones </span>
                    <textarea style="width: 100%" [value]="item.observations" type="text" disabled> </textarea>
                </div>

            </div>
        </div>

    </div>
</div>

<div class="col-12">
    <div class="row justify-content-between">
        <button (click)="onNoClick()" class="btn btn-raised btn-default waves-effect">
      <i class="zmdi zmdi-close mr-2"></i>
      Cerrar
    </button>
        <button (click)="registerMedicalReevaluation()"
                [disabled]="reevaluationForm.invalid"
                class="c-white float-right btn btn-raised btn-primary waves-effect">
      <i class="zmdi zmdi-collection-text mr-2"></i>
      Registrar reevaluación
    </button>
    </div>
</div>
