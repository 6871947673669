
        <div class="block-header">
            <h2>Bienvenido</h2>
            <small class="text-muted">CannaHope - App</small>
        </div>
        


        <!-- <a href="http://localhost:2745/api/medicalConsultation/getConsultationRecipeFile/getAll" target="_blank" rel="noopener noreferrer">dfsdfsdfsdf</a> -->

        <div class="row clearfix">
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="info-box-4 hover-zoom-effect">
                    <div class="icon"> <i class="zmdi zmdi-account col-blue"></i> </div>
                    <div class="content">
                        <div class="text">Pacientes</div>
                        <div class="number"> {{ dashboardData?.totalPatients }} </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="info-box-4 hover-zoom-effect">
                    <div class="icon"> <i class="zmdi zmdi-account col-green"></i> </div>
                    <div class="content">
                        <div class="text">Consultas hoy</div>
                        <div class="number">{{ dashboardData?.appointmentsToday }}</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="info-box-4 hover-zoom-effect">
                    <div class="icon"> <i class="zmdi zmdi-bug col-blush"></i> </div>
                    <div class="content">
                        <div class="text">Today's Operations</div>
                        <div class="number">0</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="info-box-4 hover-zoom-effect">
                    <div class="icon"> <i class="zmdi zmdi-balance col-cyan"></i> </div>
                    <div class="content">
                        <div class="text">Hospital Earning</div>
                        <div class="number">0</div>
                    </div>
                </div>
            </div>
        </div>