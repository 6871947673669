import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BankService } from 'src/app/services/bank.service'
import { Bank } from 'src/app/models/patientConsultation'

@Component({
  selector: 'app-registered-payment',
  templateUrl: './registered-payment.component.html',
  styleUrls: ['./registered-payment.component.scss']
})
export class RegisteredPaymentComponent implements OnInit {

  public paymentForm: FormGroup;
  public bankList: Bank[];

  constructor(
    public dialogRef: MatDialogRef<RegisteredPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public paymentData: any,
    private builder: FormBuilder,
    private bankService: BankService
  ) {
    this.getBankList();
    this.setPaymentForm(paymentData);
  }

  ngOnInit(): void {
  }

  private setPaymentForm(paymentData: any): void {
    if (paymentData == undefined) {
      paymentData = {
        paymentMethod: "",
        operationCode: "",
        value: "",
        bankAccount: "",
        registerDate: ""
      }
    }
    this.paymentForm = this.builder.group({
      appointment: [null],
      paymentMethod: [paymentData.paymentMethod, Validators.required],
      operationCode: [paymentData.operationCode],
      value: [paymentData.value, Validators.required],
      bankAccount: [paymentData.bankAccount],
      registerDate: [paymentData.registerDate, Validators.required]
    });
  }

  public getBankList(): void {
    this.bankService.findAll().subscribe(resp => {
      this.bankList = resp;
    });
  }
}
