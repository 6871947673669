import { DecimalPipe } from '@angular/common';
import { ComplementaryExam } from './complementaryExam.model';
import { Patient } from './patient';
import { User } from './user';

export class MedicalConsultation {
    // tslint:disable-next-line: variable-name
    _id: string;
    patient: string;
    doctor: any;
    patientProblems: PatientProblem[] = [];
    medicalEvaluation: MedicalEvaluation = new MedicalEvaluation();
    physicalExam: string; // PhysicalExam = new PhysicalExam();
    patientStory: string;
    createDate: any;
    medicalDiagnostic: MedicalDiagnostic = new MedicalDiagnostic();
    complementaryExams: ComplementaryExam[];
    consultationReason: string;
    reevaluations: MedicalReevaluation[] = [];
    idAppointment: string;
    recomendations: string;
}

export class MedicalDiagnostic {
    disease: Disease[] = [];
    description: string;
    medicalTreatment: MedicalTreatment[] = [];
}

export class MedicalTreatment {
    description: string;
    viaAdministracion: string;
    ratio: string;
    concentracion: string;
    fitocannabinoides: string;
    frequency: string;
    amountPerDose: string;
    createDate?: Date;
    observations: string;
    type: string;
    conditions: string;
    recommendations: string;
}

export class Disease {
    name: string;
    description: string;
    value: boolean;
}

export class PhysicalExam {
    generalSummary: BodyPart[] = [];
    visionAnalysis: VisionAnalysis = new VisionAnalysis();
}

export class VisionAnalysis {
    agudezaVisual: string = '';
    conCorrectores: string = '';
    fondoDeOjos: string = '';
    visionProfunda: string = '';
    estenopeico: string = '';
    conclusion: string = '';
    visionDeColores: string = '';
}

export class BodyPart {
    name: string;
    description: string;
    value: boolean;
}

export class BlesseDementiaScale {
    housework?: number;
    smallAmount?: number;
    rememberItems?: number;
    orientSelf?: number;
    navigateStreet?: number;
    valueEnvironment?: number;
    recallEvents?: number;
    recallPast?: number;
    eat?: number;
    wear?: number;
    toiletTraining?: number;
    withdrawal?: number;
    egocentrism?: number;
    interestOthers?: number;
    dullActivity?: number;
    emotionalControl?: number;
    inappropriateHilarity?: number;
    emotionalResponse?: number;
    sexualIndiscretions?: number;
    interestHobbies?: number;
    progressiveApathy?: number;
    unjustifiedHyperactivity?: number;
    sum?: string;
}

export class AnxietyScale {
    feelingExcited?: number;
    worried?: number;
    feelingIrritable?: number;
    troubleRelaxing?: number;
    difficultySleeping?: number;
    headaches?: number;
    symptoms?: number;
    concernedHealth?: number;
    fallingAsleep?: number;
    lowEnergy?: number;
    count?: number;
}

export class DepressionScale {
    lostInterest?: number;
    lostConfidence?: number;
    feelHopeless?: number;
    difficultyConcentrating?: number;
    lostWeight?: number;
    wakeupEarly?: number;
    feelSluggish?: number;
    feelWorse?: number;
    count?: number;
}

export class EdmontonScale {
    pain?: number;
    exhausted?: number;
    somnolent?: number;
    nausea?: number;
    apetito?: number;
    breathe?: number;
    discouraged?: number;
    nervous?: number;
    sleep?: number;
    feel?: number;
    anotherProblem?: number;
}

export class MedicalEvaluation {
    anamnesis: string;
    clinicalExamination: ClinicalExamination = new ClinicalExamination();
    painScale: string = '0';
    solicitudeScale: string = '0';
    ectoscopy: string;
    mentalStatus: string;
    createDate: string;
    seizures: {
        quantity: string;
        frequency: string;
    };
    blesseDementiaScale: BlesseDementiaScale = new BlesseDementiaScale();
    anxietyScale: AnxietyScale = new AnxietyScale();
    depressionScale: DepressionScale = new DepressionScale();
    edmontonScale: EdmontonScale = new EdmontonScale();
    seizuresQuantity: string;
    seizuresFrequency: string;
    comment: string;
}

export class ClinicalExamination {
    // tslint:disable-next-line: variable-name
    _id?: string;
    talla: number;
    peso: number;
    perimetroabdominal: number;
    satO2: number;
    pa: string;
    fr: number;
    fc: number;
    imc: any
}

export class PatientProblem {
    value: boolean;
    isEnabled?: boolean;
    name: string;
    description: string;
}

export class ExamReason {
    value: boolean;
    isEnabled?: boolean;
    name: string;
    description: string;
}

export class Medication {
    value: boolean;
    isEnabled?: boolean;
    name: string;
    description: string;
}

export class Bank {
    value: boolean;
    isEnabled?: boolean;
    name: string;
    description: string;
}

export class MedicalReevaluation {
    medicalConsultation: string;
    description: string;
    createDate: Date;
    painScale: string;
    painSacale: string;
    medicalTreatment: any[];
}

export interface IMedicalConusltation {
    patientProblems?: PatientProblem[];
    complementaryExams?: ComplementaryExam[];
    reevaluations?: MedicalReevaluation[];
    _id?: string;
    patient?: Patient;
    doctor?: User;
    medicalEvaluation?: MedicalEvaluation;
    physicalExam?: PhysicalExam;
    medicalDiagnostic?: MedicalDiagnostic;
    patientStory?: string;
    createDate?: string;
    consultationReason?: string;
    recomendations?: string;
    idClinicalExamination?: string;
}
