import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DiseaseService } from 'src/app/services/disease.service';

@Component({
  selector: 'app-create-disease-modal',
  templateUrl: './create-disease-modal.component.html',
  styleUrls: ['./create-disease-modal.component.scss']
})
export class CreateDiseaseModalComponent implements OnInit {


  public formDisease: any = {
    name: '',
    description: ''
  };

  constructor(
    public dialogRef: MatDialogRef<CreateDiseaseModalComponent>,
    private diseaseSrv: DiseaseService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public saveDisease(): void {
    this.diseaseSrv.save(this.formDisease).subscribe(resp => {
      this.dialogRef.close();
    });
  }
}
