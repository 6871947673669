import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResoursesService } from 'src/app/services/resourses.service';
import { Observable } from 'rxjs';
import { AppointmentStatus } from '../models/appointment-status.interface';
import { ApiResp } from 'src/app/models/apiResp';
import { appointmentStatusRoutes } from '../global/apiRoutes.routes';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppointmentStatusService {

  constructor(
    private resoucers: ResoursesService,
    private http: HttpClient
  ) { }

  public findAll(): Observable<AppointmentStatus[]> {
    return this.http.get<ApiResp>(appointmentStatusRoutes.getAll, { headers: this.resoucers.getHeaders() }).pipe(
      map(resp => resp.data),
      catchError(error => this.resoucers.handleError(error)));
  }
}
