<div class="container">
    <div class="card-top"></div>
    <div class="card">
        <h1 class="title"><span>CannaHope</span>Error 404 <div class="msg">Página no encontrada</div></h1>
        <div class="col-md-12 text-center">
            <div class="input-group"> <span class="input-group-addon"> <i class="zmdi zmdi-search"></i> </span>
                <div class="form-line">
                    <input type="text" class="form-control" name="search" placeholder="Search..." required>
                </div>
            </div>
             <div> <a class="btn btn-raised g-bg-cyan waves-effect" href="/app">Ir a dashboard</a> </div>
        </div>
    </div>
</div>
