import { Patient } from './patient';
import { User } from './user';
import { IMedicalSpeciality } from './medicalSpeciality';
import { DoctorAvailability } from './doctorAvailability.model';



export class Appointment {
    paymentData: any[];
    _id: string;
    patient: Patient;
    doctor: User;
    date: Date;
    specialty?: IMedicalSpeciality;
    patientProblem: string;
    doctorAvailability: DoctorAvailability;
    paymentStatus: string;
    createdBy: User;
    createdAt: string;
    status: string;
    dateString: string;
    type: string;
}
