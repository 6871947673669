<aside id="leftsidebar" class="sidebar">
    <div class="user-info">
        <div class="admin-image">
            <img *ngIf="user.image" [src]="user.image | getDocuments: 'userImage'" alt="user" class="img-size">

            <img *ngIf="!user.image && user.sex === 'FEMENINO'" src="assets/images/patients/random-avatar3.jpg"
                alt="user" class="img-size">

            <img *ngIf="!user.image && user.sex === 'MASCULINO'" src="assets/images/patients/random-avatar1.jpg"
                alt="user" class="img-size">
        </div>
        <div class="admin-action-info"> <span>Bienvenido</span>
            <h3> {{ (user.names + ' ' + user.surenames ) | slice:0:13 }}. </h3>
            <ul>
                <li><a [routerLink]="" class="js-right-sidebar" data-close="true"><i
                            class="zmdi zmdi-settings"></i></a></li>
                <li><a data-placement="bottom" title="Full Screen" (click)="userLogout()"><i
                            class="zmdi zmdi-sign-in pointer"></i></a></li>
            </ul>
        </div>
        <div class="quick-stats" *ngIf="!rol.paciente()" >
            <h5>Resumen</h5>
            <ul>
                <li><span> {{ dashboardData?.totalPatients }} <i>Pacientes</i></span></li>
                <li><span> {{ dashboardData?.appointmentsToday }} <i>Citas</i></span></li>
                <li><span>{{ dashboardData?.appointmentsAttended || 0 }}<i>Atendidos</i></span></li>
            </ul>
        </div>
    </div>
    <div class="menu">
        <ul class="list">
            <li class="header">MENÚ DE NAVEGACIÓN</li>

            <li *ngIf="rol.administrador() || rol.recepcionista()">
                <a href="javascript:void(0);" class="menu-toggle">
                    <i class="zmdi zmdi-book-image"></i>
                    <span>Gestión de consultas</span> </a>
                <ul class="ml-menu">
                    <li><a [routerLink]="['consultas/registrar']"> Agendar consulta </a></li>
                    <li><a [routerLink]="['consultas/listadoHoy']"> Consultas de hoy </a></li>
                    <li><a [routerLink]="['consultas/listado']"> Consultas general </a></li>
                    <li><a [routerLink]="['consultas/calendario']"> Calendario general</a></li>
                </ul>
            </li>


            <li *ngIf="rol.administrador() || rol.medico()"><a href="javascript:void(0);" class="menu-toggle"><i
                        class="zmdi zmdi-account-add"></i><span>Doctores</span> </a>
                <ul class="ml-menu">
                    <li *ngIf="rol.administrador() || rol.medico()"><a [routerLink]="['doctor/programacionDoctor']" href="#">Mi programación</a></li>
                    <li *ngIf="rol.administrador() || rol.medico()"><a [routerLink]="['doctor/calendarioMedico']" href="#">Mi calendario</a></li>

                    <li *ngIf="rol.administrador()" ><a [routerLink]="['doctor/listaDoctores']" routerLinkActive="active">Lista de doctores</a></li>
                    <li *ngIf="rol.administrador()" ><a [routerLink]="['doctor/registrarDoctor']" routerLinkActive="active">Registrar doctor</a></li>
                    <li  *ngIf="rol.administrador()"><a [routerLink]="['doctor/franjaDisponibilidad']">Horarios</a></li>
                    <li  *ngIf="rol.administrador()"><a [routerLink]="['doctor/especialidades']">Especialidades</a></li>
                </ul>
            </li>

            <li *ngIf="rol.administrador() || rol.medico() || rol.recepcionista()">
                <a href="javascript:void(0);" class="menu-toggle"><i
                        class="zmdi zmdi-account-o"></i><span>Pacientes</span> </a>
                <ul class="ml-menu">
                    <li><a [routerLink]="['pacientes']">Lista de pacientes</a></li>
                    <li><a [routerLink]="['pacientes', 'registrarPaciente']">Registrar paciente</a></li>
                </ul>
            </li>

            <li *ngIf="rol.administrador()"><a href="javascript:void(0);" class="menu-toggle"><i
                        class="zmdi zmdi-settings"></i><span>Mantenimiento</span> </a>
                <ul class="ml-menu">
                    <li><a [routerLink]="['usuarios']">Usuarios</a></li>
                    <li><a [routerLink]="['mantenimientosGenerales']">Mantenimientos generales</a></li>
                </ul>
            </li>

            <li *ngIf="rol.administrador() || rol.medico()">
                <a href="javascript:void(0);" class="menu-toggle">
                    <i class="zmdi zmdi-case"></i>
                    <span>Reportes</span> </a>
                <ul class="ml-menu">
                    <li><a [routerLink]="['miPacientes']" href="#">Pacientes</a></li>
                </ul>
            </li>

            <li *ngIf="rol.usuario() || rol.paciente()"><a href="javascript:void(0);" class="menu-toggle">
                    <i class="zmdi zmdi-account-circle"></i> <span>Mi cuenta</span> </a>
                <ul class="ml-menu">
                    <li><a [routerLink]="['usuarios']">Historial de consultas</a></li>
                    <li><a [routerLink]="['usuarios']"> Agendar una consulta </a></li>
                    <li><a [routerLink]="['mantenimientosGenerales']">Mi perfil</a></li>
                </ul>
            </li>
        </ul>
    </div>
</aside>
