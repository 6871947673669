<div class="patient-management__registerAppointmentForm">
    <mat-vertical-stepper [linear]="true" #stepper>

        <mat-step>
            <form>
                <ng-template matStepLabel>Datos registrados en admisión</ng-template>
                <div class="row mt-1">
                    <div class="col-12 col-md-4">
                        <mat-form-field>
                            <mat-label> Talla(cm) </mat-label>
                            <input matInput name="talla" type="number" step="0.01" placeholder="0.00" (keyup)="setImc()" [(ngModel)]="patientConsultation.medicalEvaluation?.clinicalExamination.talla">
                        </mat-form-field>
                    </div>

                    <div class="col-12 col-md-4">
                        <mat-form-field>
                            <mat-label> Peso(kg) </mat-label>
                            <input matInput name="peso" type="number" step="0.01" placeholder="0.00" (keyup)="setImc()" [(ngModel)]="patientConsultation.medicalEvaluation?.clinicalExamination.peso">
                        </mat-form-field>
                    </div>


                    <div class="col-12 col-md-4">
                        <mat-form-field>
                            <mat-label> Índice de masa corporal </mat-label>
                            <input matInput name="imc" type="number" step="0.01" placeholder="0.01" [(ngModel)]="patientConsultation.medicalEvaluation?.clinicalExamination.imc">
                        </mat-form-field>
                    </div>

                    <div class="col-12 col-md-4">
                        <mat-form-field>
                            <mat-label> Sat02 </mat-label>
                            <input matInput name="satt02" type="number" step="0.01" [(ngModel)]="patientConsultation.medicalEvaluation?.clinicalExamination.satO2">
                        </mat-form-field>
                    </div>

                    <div class="col-12 col-md-4">
                        <mat-form-field>
                            <mat-label> Frecuencia respiratoria(F.R.) </mat-label>
                            <input matInput name="fr" type="number" step="0.01" placeholder="0.01" [(ngModel)]="patientConsultation.medicalEvaluation?.clinicalExamination.fr">
                        </mat-form-field>
                    </div>


                    <div class="col-12 col-md-4">
                        <mat-form-field>
                            <mat-label> Frencuencia cardiaca </mat-label>
                            <input matInput name="fc" type="number" step="0.01" [(ngModel)]="patientConsultation.medicalEvaluation?.clinicalExamination.fc">
                        </mat-form-field>
                    </div>

                    <div class="col-12 col-md-4">
                        <mat-form-field>
                            <mat-label> Presión arterial </mat-label>
                            <input matInput name="pa" type="text" step="0.01" [(ngModel)]="patientConsultation.medicalEvaluation?.clinicalExamination.pa">
                        </mat-form-field>
                    </div>

                </div>

                <div>
                    <button matStepperNext class="btn  btn-raised bg-cyan btn-sm waves-effect pointer m-1" mat-button>
                        <span>Siguiente</span>
                        <i class="zmdi zmdi-long-arrow-down ml-1"></i>
                    </button>
                </div>
            </form>
        </mat-step>


        <!-- step 2  current problems and patient story -->
        <mat-step>
            <ng-template matStepLabel>Problemas actuales de paciente</ng-template>
            <div class="row mt-2">
                <div class="col-12 mb-3">
                    <button (click)="addPatientProblemDialog()" class="btn  btn-raised btn-sm bg-blue-grey waves-effect pointer m-1 right">
                        <i class="zmdi zmdi-collection-plus"></i>
                        Registrar problema
                    </button>
                </div>

                <div class="col-12 col-md-3 mt-3" *ngFor="let patientProblem of patientProblems">
                    <mat-checkbox [(ngModel)]="patientProblem.value" [value]="patientProblem.value" [name]="patientProblem.name">
                        {{ patientProblem.name }}
                    </mat-checkbox>
                </div>

                <div class="col-12 mt-5">
                    <mat-form-field class="w100p">
                        <mat-label>Relato del paciente</mat-label>
                        <textarea name="patientStory" [(ngModel)]="patientConsultation.patientStory" rows="5" matInput placeholder="Relato completo de síntomas que padece el paciente" required>
                        </textarea>
                    </mat-form-field>
                </div>

                <div class="col-12 mt-5">
                    <mat-form-field class="w100p">
                        <mat-label>Exámen físico</mat-label>
                        <textarea name="physicalExam" [(ngModel)]="patientConsultation.physicalExam" rows="5" matInput placeholder="Agregar campo exámen físico" required>
                        </textarea>
                    </mat-form-field>
                </div>

            </div>

            <div>
                <button class="btn  btn-raised bg-cyan btn-sm waves-effect pointer m-1" mat-button matStepperPrevious>
                    <span>Regresar</span>
                    <i class="zmdi zmdi-long-arrow-up ml-1"></i>
                </button>
                <!--  -->
                <button (click)="validateStepOne()" class="btn  btn-raised bg-cyan btn-sm waves-effect pointer m-1" mat-button>
                    <span>Siguiente</span>
                    <i class="zmdi zmdi-long-arrow-down ml-1"></i>
                </button>
            </div>

        </mat-step>



        <!-- 2 patient Anamnesis-->
        <mat-step>
            <form>
                <ng-template matStepLabel>Estado físico de paciente</ng-template>

                <div class="row mt-3">
                    <div class="col-12">
                        <div class="card-tite h6"> Escala de dolor </div>
                        <mat-form-field class="w100p">
                            <mat-label> Escala de dolor presentado por el paciente </mat-label>
                            <mat-select [(ngModel)]="patientConsultation.medicalEvaluation.painScale" name="painScale">
                                <mat-option value="1"> 1</mat-option>
                                <mat-option value="2"> 2</mat-option>
                                <mat-option value="3"> 3</mat-option>
                                <mat-option value="4"> 4</mat-option>
                                <mat-option value="5"> 5</mat-option>
                                <mat-option value="6"> 6</mat-option>
                                <mat-option value="7"> 7</mat-option>
                                <mat-option value="8"> 8</mat-option>
                                <mat-option value="9"> 9</mat-option>
                                <mat-option value="10"> 10</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-12">
                        <div class="card-tite h6"> Escala de ansiedad </div>
                        <mat-form-field class="w100p">
                            <mat-label> Escala de ansiedad presentado por el paciente </mat-label>
                            <mat-select [(ngModel)]="patientConsultation.medicalEvaluation.solicitudeScale" name="solicitudeScale" id="solicitudeScale">
                                <mat-option value="1"> 1</mat-option>
                                <mat-option value="2"> 2</mat-option>
                                <mat-option value="3"> 3</mat-option>
                                <mat-option value="4"> 4</mat-option>
                                <mat-option value="5"> 5</mat-option>
                                <mat-option value="6"> 6</mat-option>
                                <mat-option value="7"> 7</mat-option>
                                <mat-option value="8"> 8</mat-option>
                                <mat-option value="9"> 9</mat-option>
                                <mat-option value="10"> 10</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <!-- Exámenes auxiliares e informes -->
                <app-blesse-detail-accordion [blesseDementiaScale]="patientConsultation.medicalEvaluation.blesseDementiaScale" [i]="0">
                </app-blesse-detail-accordion>
                <!-- Exámenes auxiliares e informes -->

                <!-- Escala de ansiedad y depresión -->
                <app-anxiety-detail-accordion [anxietyScale]="patientConsultation.medicalEvaluation.anxietyScale" [depressionScale]="patientConsultation.medicalEvaluation.depressionScale" [i]="1">
                </app-anxiety-detail-accordion>
                <!-- Escala de ansiedad y depresión -->

                <!-- Escala de edmonton -->
                <app-edmonton-detail-accordion [edmontonScale]="patientConsultation.medicalEvaluation.edmontonScale" [i]="2">
                </app-edmonton-detail-accordion>
                <!-- Escala de edmonton -->

                <div class="row mt-3">
                    <div class="col-12">
                        <div class="card-tite h6"> CONVULSIONES </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <mat-form-field class="example-full-width w100p">
                                    <mat-label>Cantidad</mat-label>
                                    <input [(ngModel)]="patientConsultation.medicalEvaluation.seizuresQuantity" matInput name="seizuresQuantity">
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-md-6">
                                <mat-form-field class="example-full-width w100p">
                                    <mat-label>Frecuencia</mat-label>
                                    <input [(ngModel)]="patientConsultation.medicalEvaluation.seizuresFrequency" matInput name="seizuresFrequency">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="card-tite h6"> Comentarios </div>
                        <div class="row">
                            <div class="col-12 col-md-12">
                                <mat-form-field class="example-full-width">
                                    <textarea rows="4" [(ngModel)]="patientConsultation.medicalEvaluation.comment" matInput name="comment"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-5">
                    <div class="col-12">
                        <div class="card-tite h6"> Anamnesis </div>
                        <mat-form-field class="w100p">
                            <mat-label> Anamnesis </mat-label>
                            <mat-select [(ngModel)]="patientConsultation.medicalEvaluation.anamnesis" name="anamnesis" id="anamnesis">
                                <mat-option value="ABEG"> ABEG - Aparente buen estado general</mat-option>
                                <mat-option value="AREG"> AREG - Aparente regular estado general </mat-option>
                                <mat-option value="AMEG"> AMEG - Aparente mal estado general </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>


                <div>
                    <button class="btn  btn-raised bg-cyan btn-sm waves-effect pointer m-1" mat-button matStepperPrevious>
                        <span>Regresar</span>
                        <i class="zmdi zmdi-long-arrow-up ml-1"></i>
                    </button>
                    <button (click)="validateStepTwo()" class="btn btn-raised bg-cyan btn-sm waves-effect pointer m-1" mat-button>
                        <span>Siguiente</span>
                        <i class="zmdi zmdi-long-arrow-down ml-1"></i>
                    </button>
                </div>
            </form>
        </mat-step>

        <!-- step 3 patien diagnostic and medication -->
        <mat-step>
            <ng-template matStepLabel>Diagnóstico</ng-template>

            <div class="row mt-3">

                <div class="col-12">
                    <button (click)="addDiseaseDialog()" class="btn  btn-raised btn-sm bg-blue-grey waves-effect pointer m-1 right">
                        <i class="zmdi zmdi-collection-plus"></i>
                        Registrar enfermedad
                    </button>
                </div>

                <div class="col-12 col-md-12 mt-3 mb-4">
                    <mat-form-field class="example-full-width">
                        <mat-label> Diagnóstico médico - Ingrese enfermedad </mat-label>
                        <input type="text" placeholder="Escriba el nombre de la enfermedad" aria-label="Number" matInput required [formControl]="myControl" [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option (click)="setDicease(option)" (keydown.enter)="setDicease(option)" *ngFor="let option of filteredOptions | async" [value]="option.name">
                                {{option.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div class="col-12">
                    <div class="alert alert-info" *ngFor="let disease of diseaseListSelected">
                        <strong> {{ disease.name }} </strong> <i (click)="deleteDisease(disease)" class="pointer zmdi zmdi-close right pull-right align-self-end"></i>
                    </div>
                </div>

                <div class="col-12 col-md-12">
                    <mat-form-field class="w100p">
                        <mat-label> Descripción de diagnóstico </mat-label>
                        <textarea name="medicalDiagnostic" [(ngModel)]="patientConsultation.medicalDiagnostic.description" rows="4" matInput placeholder="Debe agregar un detalle, descripcion o razón del diagnotisco dictaminado." required>
                    </textarea>
                    </mat-form-field>
                </div>
            </div>


            <div class="row">
                <div class="col-12">
                    <h6 class="h2">Tratamiento médico</h6>
                </div>
            </div>

            <div class="row mt-3 mb-5">
                <div class="col-12 col-md-8">
                    <mat-form-field class="w100p">
                        <mat-label>Seleccionar una vía de administración</mat-label>
                        <mat-select [(value)]="currentMedicalTreatment.viaAdministracion">
                            <mat-option value="Sublingual">Sublingual</mat-option>
                            <mat-option value="Vaporizada">Vaporizada</mat-option>
                            <mat-option value="Topica">Tópica</mat-option>
                            <mat-option value="Vaginal">Vaginal</mat-option>
                            <mat-option value="Rectal">Rectal</mat-option>
                            <mat-option value="Oral">Oral</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-12 col-md-4">
                    <mat-form-field class="w100p">
                        <mat-label>Fitocannabinoides</mat-label>
                        <mat-select [(value)]="currentMedicalTreatment.fitocannabinoides">
                            <mat-option *ngFor="let ft of fitocannabinoides | async" [value]="ft.name"> {{ ft.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>


                <!-- <div class="col-12 col-md-4">
                    <mat-form-field class="w100p">
                        <mat-label>Ratio</mat-label>
                        <mat-select [(value)]="currentMedicalTreatment.ratio">
                            <mat-option value="1/1">1/1</mat-option>
                            <mat-option value="2/1">2/1</mat-option>
                            <mat-option value="5/1">5/1</mat-option>
                            <mat-option value="10/1">10/1</mat-option>
                            <mat-option value="20/1">20/1</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-12 col-md-4">
                    <mat-form-field class="w100p">
                        <mat-label>Concentracón</mat-label>
                        <mat-select [(value)]="currentMedicalTreatment.concentracion">
                            <mat-option value="3%">3%</mat-option>
                            <mat-option value="5%">5%</mat-option>
                            <mat-option value="10%">10%</mat-option>
                            <mat-option value="20%">20%</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->

                <div class="col-12 col-md-4">
                    <mat-form-field class="example-full-width">
                        <mat-label>Ratio</mat-label>
                        <input [(ngModel)]="currentMedicalTreatment.ratio" matInput>
                    </mat-form-field>
                </div>

                <div class="col-12 col-md-4">
                    <mat-form-field class="example-full-width">
                        <mat-label>Concentración</mat-label>
                        <input [(ngModel)]="currentMedicalTreatment.concentracion" matInput>
                    </mat-form-field>
                </div>

                <div class="col-12 col-md-4">
                    <mat-form-field class="w100p">
                        <mat-label>
                            Frecuencia
                            <span></span>
                        </mat-label>
                        <mat-select [(value)]="currentMedicalTreatment.frequency">
                            <mat-option value="0">0 </mat-option>
                            <mat-option value="1">1</mat-option>
                            <mat-option value="2">2</mat-option>
                            <mat-option value="3">3</mat-option>
                            <mat-option value="4">4</mat-option>
                            <mat-option value="5">5</mat-option>
                            <mat-option value="6">6</mat-option>
                            <mat-option value="7">7</mat-option>
                            <mat-option value="8">8</mat-option>
                            <mat-option value="9">9</mat-option>
                            <mat-option value="10">10</mat-option>
                            <mat-option value="CONDICIONAL">CONDICIONAL</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-12- col-md-3" *ngIf="currentMedicalTreatment.viaAdministracion === 'Sublingual'">
                    <mat-form-field class="w100p">
                        <mat-label>
                            Gotas por día:
                            <span></span>
                        </mat-label>
                        <input style="width: 100%" [(ngModel)]="currentMedicalTreatment.amountPerDose" type="number" matInput>
                        <!-- <mat-select [(value)]="currentMedicalTreatment.amountPerDose">
                            <mat-option value="0">0 </mat-option>
                            <mat-option value="1">1</mat-option>
                            <mat-option value="2">2</mat-option>
                            <mat-option value="3">3</mat-option>
                            <mat-option value="4">4</mat-option>
                            <mat-option value="5">5</mat-option>
                            <mat-option value="6">6</mat-option>
                            <mat-option value="7">7</mat-option>
                            <mat-option value="8">8</mat-option>
                            <mat-option value="9">9</mat-option>
                            <mat-option value="10">10</mat-option>
                        </mat-select> -->
                    </mat-form-field>
                </div>

                <div class="col-12">
                    <mat-form-field class="example-full-width">
                        <mat-label>Observaciones</mat-label>
                        <textarea rows="4" [(ngModel)]="currentMedicalTreatment.observations" matInput></textarea>
                    </mat-form-field>
                </div>

                <div class="col-12">
                    <mat-form-field class="example-full-width">
                        <mat-label>Condiciones</mat-label>
                        <textarea rows="4" [(ngModel)]="currentMedicalTreatment.conditions" matInput></textarea>
                    </mat-form-field>
                </div>


                <div class="col-12 col-md-3">
                    <button (click)="addMedicalTreatment(currentMedicalTreatment)" class="btn  btn-raised btn-sm bg-blue-grey waves-effect pointer m-1" mat-button>
                        <i class="zmdi zmdi-collection-plus"></i>
                        <span>Agregar</span>
                    </button>
                </div>

                <div class="col-12 mt-3" *ngFor="let item of medicalTreatmentList; let i = index">
                    <div class="row justify-content-between">
                        <span class="h5"> Tratamiento {{ i + 1 }} </span>
                        <button (click)="deleteMedicalTreatament(item)" class="btn btn-raised btn-sm bg-blue-grey waves-effect pointer" mat-button>
                            <i class="pointer zmdi zmdi-close"></i>
                        </button>
                    </div>

                    <div class="row custom-border">
                        <div class="col-12 col-md-4">
                            <span> V. de administración. </span>
                            <input style="width: 100%;" [value]="item.viaAdministracion" type="text" disabled>
                        </div>

                        <div class="col-12 col-md-4">
                            <span> Fitocannabinoides. </span>
                            <input style="width: 100%" [value]="item.fitocannabinoides" type="text" disabled>
                        </div>

                        <div class="col-12 col-md-4">
                            <span> Ratio </span>
                            <input style="width: 100%" [value]="item.ratio" type="text" disabled>
                        </div>

                        <div class="col-12 col-md-4">
                            <span> Concentración </span>
                            <input style="width: 100%" [value]="item.concentracion" type="text" disabled>
                        </div>

                        <div class="col-12 col-md-4">
                            <span> Frecuencia(veces al día) </span>
                            <input style="width: 100%" [value]="item.frequency" type="text" disabled>
                        </div>

                        <div class="col-12 col-md-4" *ngIf="item.viaAdministracion === 'Sublingual'">
                            <span> Gotas por día. </span>
                            <input style="width: 100%" [value]="item.amountPerDose" type="text" disabled>
                        </div>

                        <div class="col-12">
                            <span> Observaciones </span>
                            <input style="width: 100%" [value]="item.observations" type="text" disabled>
                        </div>

                        <div class="col-12">
                            <span> Condicional de tratamiento </span>
                            <input style="width: 100%" [value]="item.conditions" type="text" disabled>
                        </div>

                    </div>

                </div>
            </div>
            <div>
                <button class="btn  btn-raised bg-cyan btn-sm waves-effect pointer m-1" mat-button matStepperPrevious>
                    <span>Regresar</span>
                    <i class="zmdi zmdi-long-arrow-up ml-1"></i>
                </button>
                <button (click)="validateStepThree()" class="btn btn-raised bg-cyan btn-sm waves-effect pointer m-1" mat-button>
                    <span>Siguiente</span>
                    <i class="ml-2 zmdi zmdi-long-arrow-down"></i>
                </button>
            </div>
        </mat-step>


        <!-- Step 4 and aditional exams  -->
        <mat-step>
            <ng-template matStepLabel>Recomendaciones</ng-template>
            <div class="row">

                <div class="col-12">
                    <mat-form-field class="example-full-width">
                        <mat-label>Recomendaciones</mat-label>
                        <textarea matInput [(ngModel)]="patientConsultation.recomendations" name="recomendations" rows="7"></textarea>
                    </mat-form-field>
                </div>


                <div class="col-12">

                    <div class="row">
                        <div class="col-12">
                            <h3>Exámenes complementarios</h3>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-12 col-md-5">
                            <span>Nombre de examen</span>
                        </div>

                        <div class="col-12 col-md-5">
                            <span>Descripción</span>
                        </div>
                    </div>

                    <div class="row mt-2" *ngFor="let item of complementaryExamList">
                        <div class="col-12 col-md-5">
                            <input type="text" [value]="item.name" disabled class="w100p">
                        </div>
                        <div class="col-12 col-md-7">
                            <input type="text" [value]="item.description" disabled class="w100p">
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-12 col-md-4">
                            <mat-form-field class="example-full-width">
                                <mat-label>Nombre de examen</mat-label>
                                <input matInput [(ngModel)]="complementaryExamItem.name" name="complementaryExamName">
                            </mat-form-field>
                            <!-- <mat-form-field class="w100p">
                                <mat-label>Nombre de examen</mat-label>
                                <mat-select [(value)]="complementaryExamItem.name" name="complementaryExamName" (selectionChange)="examItemChanged()">
                                    <mat-option *ngFor="let item of examReasonList" [value]="item.name">{{item.name}}</mat-option>
                                </mat-select>
                            </mat-form-field> -->
                        </div>
                        <div class="col-12 col-md-5">
                            <mat-form-field class="example-full-width">
                                <mat-label>Descripción</mat-label>
                                <input matInput [(ngModel)]="complementaryExamItem.description" name="complementaryExamDescription">
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-3">
                            <button type="button" class="btn  btn-raised bg-teal btn-block btn-sm waves-effect" (click)="setComplementaryExamList()">
                                <i class="zmdi zmdi-plus"></i>
                                AGREGAR
                            </button>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-12">
                            <button class="btn  btn-raised bg-cyan btn-sm waves-effect pointer m-1" mat-button matStepperPrevious>
                                <span>Regresar</span>
                                <i class="zmdi zmdi-long-arrow-up ml-1"></i>
                            </button>
                            <button (click)="finalStep()" class="btn  btn-raised bg-cyan btn-sm waves-effect pointer m-1" mat-button>
                                <span>Finalizar consulta</span>
                                <i class="ml-2 zmdi zmdi-collection-text"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </mat-step>
    </mat-vertical-stepper>

</div>
