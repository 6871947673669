import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import ApiRoutes from '../global/apiRoutes.routes';
import { catchError, map } from 'rxjs/operators';
import { ResoursesService } from './resourses.service';
import Swal from 'sweetalert2';
import { ApiResp } from '../models/apiResp';
import { User } from '../models/user';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    public resourses: ResoursesService,
    private router: Router
  ) { }

  public login(userData: any): Observable<any> {
    return this.http.post<ApiResp>(environment.apiUrl + ApiRoutes.login, userData)
      .pipe(
        map(res => {
          this.saveInStorage(res.data.user, res.data.token);
          window.location.href = 'index.html';
        }),
        catchError(error => {
          Swal.fire(error.error.message, error.error.error.name, 'error');
          return throwError(error);
        }));
  }

  private saveInStorage(user: User, token: string): void {
    localStorage.setItem('userLogged', JSON.stringify(user));
    localStorage.setItem('userToken', token);
  }

  public loggOut(): void {
    localStorage.removeItem('userLogged');
    localStorage.removeItem('userToken');
    this.router.navigate(['/login']);
  }

  public getAll(from: number, limit: number): Observable<any> {
    return this.http.get<ApiResp>(environment.apiUrl + ApiRoutes.getGetAllUser(from, limit), { headers: this.resourses.getHeaders() })
      .pipe(
        map(res => res.data),
        catchError(error => this.resourses.handleError(error))
      );
  }

  public create(user: any): Observable<HttpEvent<any>> {
    const req = new HttpRequest('POST', environment.apiUrl + ApiRoutes.createUser, user, {
      headers: this.resourses.getHeaders(),
      reportProgress: true
    });
    return this.http.request<ApiResp>(req).pipe();
  }

  public findByParams(param: string): Observable<User[]> {
    return this.http.get<ApiResp>(environment.apiUrl + ApiRoutes.getUserByParams(param), { headers: this.resourses.getHeaders() })
      .pipe(
        map(res => res.data),
        catchError(error => this.resourses.handleError(error)));
  }

  public updateuser(user: User): Observable<any> {
    const req = new HttpRequest('PUT', environment.apiUrl + ApiRoutes.updateUser(user._id), user, {
      headers: this.resourses.getHeaders(),
      reportProgress: true
    });
    return this.http.request<ApiResp>(req).pipe();
  }

  public getById(idUser: string): Observable<User> {
    return this.http.get<ApiResp>(environment.apiUrl + ApiRoutes.getUserById(idUser), { headers: {} })
      .pipe(
        map(resp => resp.data),
        catchError(error => this.resourses.handleError(error)));
  }

  public delete(idUser: string): Observable<any> {
    return this.http.delete(environment.apiUrl + ApiRoutes.deleteUser(idUser), { headers: this.resourses.getHeaders() })
      .pipe(
        map(res => this.resourses.handleResponse(res)),
        catchError(error => this.resourses.handleError(error)));
  }

  public sendForgotPassword(userEmail: string): Observable<any> {
    this.resourses.loading = true;
    return this.http.get<ApiResp>(environment.apiUrl + ApiRoutes.getsendForgotPassUrl(userEmail), { headers: this.resourses.getHeaders() })
      .pipe(
        map(resp => this.resourses.handleResponse(resp, 'login')),
        catchError(error => {
          this.resourses.loading = false;
          return this.resourses.handleError(error);
        }));
  }

  public resetPassword(user: User, token: string): Observable<any> {
    return this.http.post<ApiResp>(environment.apiUrl + ApiRoutes.resetPassword, user)
      .pipe(
        map(resp => this.resourses.handleResponse(resp, 'login')),
        catchError(error => this.resourses.handleError(error)));
  }

}
